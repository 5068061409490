import { FC } from "react";

import { Box, Text } from "@hightouchio/ui";

import { RelationshipFragment } from "src/graphql";
import { ConditionType, PropertyCondition } from "src/types/visual";
import { Row } from "src/ui/box";

import { AndOrDropdown } from "./and-or-dropdown";
import { PropertyFilter, PropertyFilterProps } from "./property-filter";

type NestedPropertyFilterProps = {
  columns: RelationshipFragment["to_model"]["filterable_audience_columns"] | undefined;
  condition: PropertyCondition;
  isFirstCondition?: boolean;
  isWrappedWithAndCondition?: boolean;
  onChange(updates: Partial<PropertyCondition>): void;
  onRemove(): void;
  onToggleWrappingConditionType(conditionType: ConditionType.And | ConditionType.Or): void;
};

export const NestedPropertyFilter: FC<Readonly<NestedPropertyFilterProps & PropertyFilterProps>> = ({
  columns,
  condition,
  isFirstCondition = false,
  isWrappedWithAndCondition = true,
  onChange,
  onRemove,
  onToggleWrappingConditionType,
  ...props
}) => {
  return (
    <Row sx={{ alignItems: "flex-start", pl: 6, width: "100%" }}>
      {isFirstCondition && (
        <Box alignSelf="center" mr={2}>
          <Text size="sm">WHERE</Text>
        </Box>
      )}
      {!isFirstCondition && (
        <AndOrDropdown
          conditionType={isWrappedWithAndCondition ? ConditionType.And : ConditionType.Or}
          onChange={() => onToggleWrappingConditionType(isWrappedWithAndCondition ? ConditionType.Or : ConditionType.And)}
        />
      )}
      <PropertyFilter
        {...props}
        columns={columns}
        condition={condition}
        traits={undefined}
        onChange={onChange}
        onRemove={onRemove}
      />
    </Row>
  );
};
