import { FC } from "react";

import { SystemStyleObject } from "@chakra-ui/styled-system";
import { Box, Text } from "@hightouchio/ui";

type Props = {
  text: string;
  sx?: SystemStyleObject;
};

export const WarningBadge: FC<Readonly<Props>> = ({ text, sx }) => {
  return (
    <Box
      alignItems="center"
      bg="#FDF4DA"
      border="1px solid #FEC84B"
      borderRadius="md"
      color="#782D0B"
      display="flex"
      height="32px"
      px={2}
      py={1.5}
      sx={{
        span: {
          whiteSpace: "nowrap",
        },
        ...sx,
      }}
    >
      <Text>{text}</Text>
    </Box>
  );
};
