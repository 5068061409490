import { FC } from "react";

import { IconProps, Icon } from "src/ui/icons/icon";

export const AudienceIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        fill="currentColor"
        d="M15.2 7.8a2.8 2.8 0 1 1-5.6 0 2.8 2.8 0 0 1 5.6 0Zm.933 8.4a3.733 3.733 0 1 0-7.466 0V19h7.466v-2.8Zm3.734-6.533a1.867 1.867 0 1 1-3.734 0 1.867 1.867 0 0 1 3.734 0ZM18 19v-2.8a5.57 5.57 0 0 0-.7-2.712 2.804 2.804 0 0 1 3.5 2.712V19H18ZM4.933 9.667a1.867 1.867 0 1 0 3.734 0 1.867 1.867 0 0 0-3.734 0ZM6.8 19v-2.8c0-.984.254-1.909.7-2.712A2.804 2.804 0 0 0 4 16.2V19h2.8Z"
      />
    </Icon>
  );
};
