import { FC, ReactElement, ReactNode } from "react";

import { Box, Text, Spinner } from "@hightouchio/ui";

import { Column, Row } from "src/ui/box";
import { FieldError } from "src/ui/field";
import { ChevronDownIcon } from "src/ui/icons";
import { NewSelect, SelectProps, PlaceholderComponentProps, SelectComponentProps } from "src/ui/new-select";

export function AttributeSelect<Value>({
  error,
  ...props
}: Omit<SelectProps<Value>, "error"> & { error?: ReactNode }): ReactElement {
  return (
    <Column sx={{ whiteSpace: "nowrap" }}>
      <NewSelect
        {...props}
        searchable
        components={{ Value, Placeholder }}
        error={Boolean(error)}
        sx={{ maxWidth: "fit-content", ...props.sx }}
      />
      {error && <FieldError error={error} sx={{ fontWeight: "semi", fontSize: 0 }} />}
    </Column>
  );
}

export const Placeholder: FC<PlaceholderComponentProps> = ({ children, disabled, error, isOpen, loading }) => (
  <Row
    sx={{
      position: "relative",
      alignItems: "center",
      px: 3,
      border: "small",
      borderColor: error ? "red" : isOpen ? "forest" : "gray.400",
      borderRadius: "6px",
      height: "32px",
      justifyContent: "space-between",
      transition: "100ms border-color",
      bg: disabled ? "base.1" : "avocado",
      color: "base.5",
      width: "fit-content",
      whiteSpace: "nowrap",
    }}
  >
    <Text>{children}</Text>
    <Row sx={{ ml: 2, width: "24px", alignItems: "center", justifyContent: "center" }}>
      {loading ? <Spinner size="sm" /> : <ChevronDownIcon color={isOpen ? "forest.light" : "gray.500"} />}
    </Row>
  </Row>
);

export const Value: FC<SelectComponentProps> = ({ error, selectedGroup, selectedOption, disabled, isOpen }) => (
  <Row
    sx={{
      position: "relative",
      alignItems: "center",
      px: 3,
      border: "small",
      borderColor: error ? "red" : isOpen ? "forest" : undefined,
      borderRadius: "6px",
      height: "32px",
      background: disabled ? "base.1" : "white",
      boxShadow: "0px 1px 2px 0px #1018280D",
      color: disabled ? "base.5" : undefined,
      width: "fit-content",
    }}
  >
    {selectedOption?.render ? selectedOption?.render({}) : <Text>{selectedOption?.label}</Text>}
    {selectedGroup && (
      <Box ml={2} sx={{ span: { color: "gray.600", whiteSpace: "nowrap", wordBreak: "none" } }}>
        <Text>{selectedGroup}</Text>
      </Box>
    )}
  </Row>
);
