import { FC, useState, useEffect } from "react";

import { Box, Select, useToast, Heading, Textarea, Switch, Text, Link, Paragraph } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import Helmet from "react-helmet";
import { useNavigate } from "react-router-dom";

import { AudienceExplore } from "src/components/audiences/audience-explore";
import { AddFolder } from "src/components/folders/add-folder";
import { useFolders } from "src/components/folders/use-folders";
import { ParentModelSelect, ParentModel } from "src/components/models/parent-model-select";
import { PermissionedButton } from "src/components/permissioned-button";
import { FormErrorProvider } from "src/contexts/form-error-context";
import { useUser } from "src/contexts/user-context";
import { ResourcePermissionGrant, useCreateAudienceMutation } from "src/graphql";
import { QueryType } from "src/types/models";
import { Column } from "src/ui/box";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { useModelState, useQueryState } from "src/utils/models";

export const CreateAudience: FC = () => {
  const { appEnableGoals } = useFlags();

  const { user, hasPermissions } = useUser();
  const canUpdateSource = hasPermissions([
    {
      resource: "source",
      grants: [ResourcePermissionGrant.Update],
    },
  ]);

  const navigate = useNavigate();
  const { toast } = useToast();
  const [parentModel, setParentModel] = useState<ParentModel | undefined>();
  const [step, setStep] = useState<number>(0);
  const { flattenedFolders, refetchFolders } = useFolders({ folderType: "audiences", viewType: "models" });
  const [selectedFolder, setSelectedFolder] = useState<string>();
  const [addFolderOpen, setAddFolderOpen] = useState(false);

  const source = parentModel?.connection;

  const [isPerformancesEnabled, setIsPeformancesEnabled] = useState((appEnableGoals && source?.plan_in_warehouse) || false);

  useEffect(() => {
    if (source?.plan_in_warehouse != null) {
      setIsPeformancesEnabled(appEnableGoals && source?.plan_in_warehouse);
    }
  }, [appEnableGoals, source?.plan_in_warehouse, setIsPeformancesEnabled]);

  const {
    queryState,
    initQueryState,
    setVisualQueryFilter,
    canRedoVisualQueryFilterChange,
    canUndoVisualQueryFilterChange,
    redoVisualQueryFilterChange,
    undoVisualQueryFilterChange,
  } = useQueryState();
  const { modelState, setName, setDescription } = useModelState();
  const { isLoading: creating, mutateAsync: createAudience } = useCreateAudienceMutation({
    onSuccess: (data, variables) => {
      toast({
        id: "create-audience",
        title: `${variables.input.name} was created`,
        variant: "success",
      });

      navigate(`/audiences/${data.insert_segments_one?.id}`);
    },
    onError: (error, variables) => {
      toast({
        id: "create-audience",
        title: `${variables.input.name} could not be created. Please try again.`,
        variant: "error",
      });

      Sentry.captureException(error);
    },
  });

  const create = async () => {
    const audienceGoals = isPerformancesEnabled
      ? (parentModel?.goals || []).map((goal) => ({ goal_id: goal.id, enabled: true }))
      : [];

    await createAudience({
      input: {
        query_type: QueryType.Visual,
        visual_query_parent_id: parentModel?.id,
        visual_query_filter: queryState?.visualQueryFilter,
        name: modelState?.name,
        description: modelState?.description,
        primary_key: parentModel?.primary_key,
        connection_id: source?.id,
        created_by: user?.id != null ? String(user?.id) : undefined,
        destination_instances: { data: [] },
        folder_id: selectedFolder,
        audience_goals: {
          data: audienceGoals,
        },
      },
    });
  };

  const steps: Step[] = [
    {
      title: "Select parent model",
      continue: "Click on a parent model to continue",
      header: <Heading>Select a parent model</Heading>,
      render: () => (
        <ParentModelSelect
          onSelect={(parentModel) => {
            setParentModel(parentModel);
            setStep(1);
          }}
        />
      ),
    },
    {
      title: "Define audience",
      render: () => (
        <FormErrorProvider>
          <AudienceExplore
            canRedo={canRedoVisualQueryFilterChange}
            canUndo={canUndoVisualQueryFilterChange}
            parentModel={parentModel}
            queryState={queryState}
            source={source}
            onRedo={redoVisualQueryFilterChange}
            onUndo={undoVisualQueryFilterChange}
            onVisualQueryFilterChange={setVisualQueryFilter}
          />
        </FormErrorProvider>
      ),
    },
    {
      title: "Finalize audience",
      disabled: !modelState?.name,
      submitting: creating,
      header: <Heading>Finalize settings for this audience</Heading>,
      render: () => (
        <Column sx={{ gap: 8, maxWidth: "600px" }}>
          <Field label="Audience name">
            <Input value={modelState?.name} onChange={(name) => setName(name)} />
          </Field>
          <Field label="Description" optional>
            <Textarea
              placeholder="Enter a description..."
              value={modelState?.description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Field>
          <Field optional label="Move to folder">
            <Select
              isClearable
              optionLabel={(folder) => folder.path.replaceAll("/", " / ")}
              optionValue={(folder) => folder.id}
              options={flattenedFolders || []}
              placeholder="Select a folder..."
              value={selectedFolder}
              onChange={(folder) => {
                setSelectedFolder(folder);
              }}
            />
            <Box mt="2">
              <PermissionedButton
                permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}
                size="sm"
                onClick={() => setAddFolderOpen(true)}
              >
                New Folder
              </PermissionedButton>
            </Box>
            {addFolderOpen && (
              <AddFolder
                toggleDisabled
                folderType="audiences"
                viewType="models"
                onClose={() => {
                  setAddFolderOpen(false);
                  refetchFolders();
                }}
              />
            )}
          </Field>
          {appEnableGoals && (
            <Box>
              <Field label="Enable performance tracking" optional sx={{ display: "flex", gap: 3 }}>
                <Switch
                  isChecked={isPerformancesEnabled}
                  isDisabled={!source?.plan_in_warehouse}
                  onChange={setIsPeformancesEnabled}
                />
              </Field>
              <Text color="text.secondary">
                View performance tracking of this audience and its split groups against metrics defined on your schema for{" "}
                <Link href={`/schema/parent-models/${parentModel?.id}`} rel="noreferrer" target="_blank">
                  {parentModel?.name}
                </Link>
                .
              </Text>
              {!source?.plan_in_warehouse && (
                <>
                  <Paragraph color="text.secondary">
                    Performance tracking requires using the{" "}
                    <Link
                      href={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#get-the-most-common-sync-error`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      Lightning sync engine
                    </Link>
                    .{" "}
                    {canUpdateSource ? (
                      <>
                        Please go to the source configuration for{" "}
                        <Link href={`/sources/${source?.id}`} rel="noreferrer" target="_blank">
                          {source?.name}
                        </Link>{" "}
                        to turn it on.
                      </>
                    ) : (
                      <>
                        If you are interested in enabling performance tracking, please ask your workspace admin to enable the
                        lightning sync engine.
                      </>
                    )}
                  </Paragraph>
                </>
              )}
            </Box>
          )}
        </Column>
      ),
    },
  ];

  useEffect(() => {
    initQueryState(null);
  }, []);

  return (
    <>
      <Helmet>
        <title>New audience</title>
      </Helmet>

      <Wizard
        fullscreen={step === 1}
        setStep={setStep}
        step={step}
        steps={steps}
        title="New audience"
        onCancel={() => {
          navigate("/audiences");
        }}
        onSubmit={create}
      />
    </>
  );
};
