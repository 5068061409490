/* eslint "no-restricted-imports": ["error", {
    "patterns": [
      {
        "group": ["*"],
        "message": "This file is used on the frontend, so try not to import extra code."
      }
    ]
  }
] */

// This is okay to import because it's just types and typeguards that the
// frontend also uses.
/* eslint-disable-next-line no-restricted-imports */
import { ErrorOriginInfo } from "./error-origin-types";
/* eslint-disable-next-line no-restricted-imports */
import { PrivateErrorTypes } from "./errors-enums";

type DocumentLink = {
  label?: string;
  type: string;
  url?: string;
};

export type ErrorCodeDetails = {
  links?: DocumentLink[];
  userFriendlyMessage: string;
};

export enum SyncRequestErrorCode {
  /**
   * Returned if the specified primary key isn't actually a primary key because it's not unique.
   * Not all planners require unique primary keys (e.g. `all` or `events` don't need it), but some
   * like in-warehouse do.
   */
  NON_UNIQUE_PRIMARY_KEY = "NON_UNIQUE_PRIMARY_KEY",

  /**
   * Returned if the specified primary key type is not suppported bv warehouse planning
   */
  UNSUPPORTED_PRIMARY_KEY_TYPE = "UNSUPPORTED_PRIMARY_KEY_TYPE",

  /**
   * Returned if the objects from the most recent run of a sync can't be found.
   * This is most common when S3 plan objects expire (as of this writing, after 30 days).
   * When that happens, a full resync is needed.
   */
  PREVIOUS_SYNC_RUN_OBJECT_MISSING = "PREVIOUS_SYNC_RUN_OBJECT_MISSING",

  /**
   * Returned if the previous sync was a migration sync from the local differ to the
   * in-warehouse differ and there were some unsupported column types that
   * couldn't be backfilled.
   */
  REMOVE_PLAN_INCOMPLETE = "REMOVE_PLAN_INCOMPLETE",

  /**
   * Returned if the previous sync had rejected removes to retry, but the model
   * has changed the types of one or more columns.
   */
  REMOVE_RETRY_CHANGED_COLUMN_TYPES = "REMOVE_RETRY_CHANGED_COLUMN_TYPES",

  /**
   * Returned if we ran out of disk space when sorting rows.
   */
  SORT_RAN_OUT_OF_DISK_SPACE = "SORT_RAN_OUT_OF_DISK_SPACE",

  /**
   * Default error code for when there isn't a more specific one.
   */
  UNSPECIFIED = "UNSPECIFIED",

  /**
   * Returned if a table necessary for in-warehouse planning is not present.
   */
  WAREHOUSE_TABLE_MISSING = "WAREHOUSE_TABLE_MISSING",
}

export enum DestinationErrorCode {
  // Include "DESTINATION_" prefix to distinguish from source invalid grants.
  DESTINATION_INVALID_GRANT = "DESTINATION_INVALID_GRANT",
  DESTINATION_INVALID_CREDENTIALS = "DESTINATION_INVALID_CREDENTIALS",

  // This is bad: the code for a given destination can't be found.
  DESTINATION_DEFINITION_NOT_FOUND = "DESTINATION_DEFINITION_NOT_FOUND",

  // Common Gateway Timeout error when destination server is not responding in a timely manner
  DESTINATION_GATEWAY_TIMEOUT = "DESTINATION_GATEWAY_TIMEOUT",

  DESTINATION_RATE_LIMIT = "DESTINATION_RATE_LIMIT",

  SALESFORCE_INACTIVE_USER = "SALESFORCE_INACTIVE_USER",
  SALESFORCE_EXPIRED_TOKEN = "SALESFORCE_EXPIRED_TOKEN",
  SALESFORCE_INACTIVE_ORG = "SALESFORCE_INACTIVE_ORG",
  SALESFORCE_RESOURCE_DOES_NOT_EXIST = "SALESFORCE_RESOURCE_DOES_NOT_EXIST",
  SALESFORCE_AUTH_FAILURE = "SALESFORCE_AUTH_FAILURE",
  SALESFORCE_REQUESTS_LIMIT_EXCEEDED = "SALESFORCE_REQUESTS_LIMIT_EXCEEDED",
  SALESFORCE_NETWORK_DIFFICULTY = "SALESFORCE_NETWORK_DIFFICULTY",
  SALESFORCE_MAX_CALL_STACK = "SALESFORCE_MAX_CALL_STACK",

  GOOGLE_ADS_INTERNAL_SERVER_ERROR = "GOOGLE_ADS_INTERNAL_SERVER_ERROR",
  GOOGLE_ADS_USER_LIST_NOT_FOUND = "GOOGLE_ADS_USER_LIST_NOT_FOUND",
  GOOGLE_ADS_TIMEOUT = "GOOGLE_ADS_TIMEOUT",
  GOOGLE_ADS_INVALID_CUSTOMER_ID = "GOOGLE_ADS_INVALID_CUSTOMER_ID",
  GOOGLE_ADS_PERMISSION_DENIED = "GOOGLE_ADS_PERMISSION_DENIED",
  GOOGLE_ADS_UNAUTHORIZED = "GOOGLE_ADS_UNAUTHORIZED",
  GOOGLE_ADS_UNAUTHENTICATED = "GOOGLE_ADS_UNAUTHENTICATED",
  GOOGLE_ADS_USER_LIST_NAME_ALREADY_IN_USE = "GOOGLE_ADS_USER_LIST_NAME_ALREADY_IN_USE",
  GOOGLE_ADS_CONCURRENT_MODIFICATION = "GOOGLE_ADS_CONCURRENT_MODIFICATION",
  GOOGLE_ADS_STORE_SALES_DIRECT_DATA_NOT_ALLOWED = "GOOGLE_ADS_STORE_SALES_DIRECT_DATA_NOT_ALLOWED",
  GOOGLE_ADS_GENERIC_INVALID_ARGUMENT = "GOOGLE_ADS_GENERIC_INVALID_ARGUMENT",

  BRAZE_SERVICE_UNAVAILABLE = "BRAZE_SERVICE_UNAVAILABLE",
  BRAZE_SERVICE_ISSUE = "BRAZE_SERVICE_ISSUE",
  BRAZE_ACCESS_DENIED = "BRAZE_ACCESS_DENIED",
  BRAZE_INVALID_REQUEST = "BRAZE_INVALID_REQUEST",

  TIKTOK_REJECTED_REPLACE_OP = "TIKTOK_REJECTED_REPLACE_OP",
  TIKTOK_AUDIENCE_ERROR = "TIKTOK_AUDIENCE_ERROR",

  HUBSPOT_SYNC_ERROR = "HUBSPOT_SYNC_ERROR",

  GOOGLE_SHEETS_INVALID_DATA_TYPE = "GOOGLE_SHEETS_INVALID_DATA_TYPE",
  GOOGLE_SHEETS_INVALID_RANGE = "GOOGLE_SHEETS_INVALID_RANGE",
  GOOGLE_SHEETS_MISSING_SHEET_ID = "GOOGLE_SHEETS_MISSING_SHEET_ID",
  GOOGLE_SHEETS_QUOTA_EXCEEDED = "GOOGLE_SHEETS_QUOTA_EXCEEDED",
  GOOGLE_SHEETS_ENTITY_NOT_FOUND = "GOOGLE_SHEETS_ENTITY_NOT_FOUND",
  GOOGLE_SHEETS_INTERNAL_ERROR = "GOOGLE_SHEETS_INTERNAL_ERROR",

  SLACK_NOT_IN_CHANNEL = "SLACK_NOT_IN_CHANNEL_ERROR",
  SLACK_IS_ARCHIVED = "SLACK_IS_ARCHIVED_ERROR",
  SLACK_INVALID_BLOCKS = "SLACK_INVALID_BLOCKS_ERROR",
  SLACK_NO_TEXT = "SLACK_NO_TEXT_ERROR",
  SLACK_NON_CALLABLE_ITERATOR = "SLACK_NON_CALLABLE_ITERATOR_ERROR",
  SLACK_UNEXPECTED_TOKEN = "SLACK_UNEXPECTED_TOKEN_ERROR",

  ANAPLAN_BAD_MAPPINGS = "ANAPLAN_BAD_MAPPINGS",
  ANAPLAN_NULL_POINTER = "ANAPLAN_NULL_POINTER",
}

/**
 * SyncRequestErrorInfo is the data stored in `sync_request.error`.
 * All fields of SyncRequestErrorInfo are stored in the DB and exposed to the client,
 * so do NOT add anything sensitive to it.
 * It doesn't extend Error because it's a serialized JSON object, not an instance of Error.
 */
export interface SyncRequestErrorInfo {
  // We call this `syncRequestErrorCode` instead of the terser `code` so that it doesn't conflict
  // with any other error codes that may be on an error cause chain.
  syncRequestErrorCode?: SyncRequestErrorCode | DestinationErrorCode;

  /**
   * User-friendly details and instructions for the error. Generally written by Hightouch
   */
  userFriendlyMessage?: string;

  /**
   * Message to show to the end user. A sanitized version of the original error
   * message.
   */
  userFacingMessage?: string;

  /**
   * The original error message. Shown by default, but may contain sensitive
   * information. If userFacingMessage is set, this is not saved to the DB.
   * Often populated from `Error.prototype.message`.
   */
  message?: string;

  /**
   * Info about the origin of the error. E.g., the error came from the source,
   * during the query operation, and it's not Hightouch's fault.
   */
  originInfo?: ErrorOriginInfo;

  /**
   * Additional info retrieved from Sanity on the syncRequestErrorCode (i.e. links and userFriendlyMessage)
   */
  errorCodeDetail?: ErrorCodeDetails | null;
}

export class NonUniquePrimaryKeyErrorInfo implements SyncRequestErrorInfo {
  syncRequestErrorCode = SyncRequestErrorCode.NON_UNIQUE_PRIMARY_KEY;
  nonUniquePrimaryKeyInfo: {
    sqlToIdentifyDuplicateRows: string;
  };
  constructor(info: { sqlToIdentifyDuplicateRows: string }) {
    this.nonUniquePrimaryKeyInfo = info;
  }
}

export class UnsupportedPrimaryKeyTypeErrorInfo
  implements SyncRequestErrorInfo
{
  syncRequestErrorCode = SyncRequestErrorCode.UNSUPPORTED_PRIMARY_KEY_TYPE;
  supportedPrimaryKeyType: {
    strings: string;
    ints: string;
    floats: string;
  };
  type: string;
  constructor(info: {
    type: string;
    supportedPrimaryKeyType: {
      strings: string;
      ints: string;
      floats: string;
    };
  }) {
    this.supportedPrimaryKeyType = info.supportedPrimaryKeyType;
    this.type = info.type;
  }
}

export class PreviousSyncRunObjectMissing implements SyncRequestErrorInfo {
  syncRequestErrorCode?: SyncRequestErrorCode.PREVIOUS_SYNC_RUN_OBJECT_MISSING;
}

export class RemovePlanIncompleteErrorInfo implements SyncRequestErrorInfo {
  syncRequestErrorCode = SyncRequestErrorCode.REMOVE_PLAN_INCOMPLETE;
  userFacingMessage?: string;
  constructor(info: { userFacingMessage: string }) {
    this.userFacingMessage = info.userFacingMessage;
  }
}

export class RemoveRetryChangedColumnTypes implements SyncRequestErrorInfo {
  syncRequestErrorCode = SyncRequestErrorCode.REMOVE_RETRY_CHANGED_COLUMN_TYPES;
  changedColumns: string[];
  constructor(info: { changedColumns: string[] }) {
    this.changedColumns = info.changedColumns;
  }
}

export class SortRanOutOfDiskSpace implements SyncRequestErrorInfo {
  syncRequestErrorCode = SyncRequestErrorCode.SORT_RAN_OUT_OF_DISK_SPACE;
}

export class WarehouseTableMissing implements SyncRequestErrorInfo {
  syncRequestErrorCode = SyncRequestErrorCode.WAREHOUSE_TABLE_MISSING;
  missingTables?: string[];
  constructor(info: { missingTables: string[] }) {
    this.missingTables = info.missingTables;
  }
}

export interface RejectedRowErrorInfo {
  rejectedRowErrorCode: string;
}

/**
 * RejectedRowError is a custom type used to signal that a sync failed due to some rows being rejected by the destination.
 */
export class SyncFailedWithRejectedRowsError extends Error {
  public readonly tag: string;

  static MESSAGE = "Some updates failed. See the syncs page for more details.";

  constructor() {
    super(SyncFailedWithRejectedRowsError.MESSAGE);
    this.tag = PrivateErrorTypes.SyncFailedWithRejectedRowsError;

    // Need to do this to get `instanceof` to work until we upgrade to ES2015 compile target.
    // TypeScript doesn't set the prototype when extending Error.
    Object.setPrototypeOf(this, SyncFailedWithRejectedRowsError.prototype);
  }
}

export type RetrySettings = (
  | { retryForever?: false; maxRetries: number }
  | { retryForever: true; maxRetries?: never }
) &
  (
    | { delayMs: number }
    | { initialDelayMs: number; maxDelayMs?: number; incrementFactor: number }
  );

/**
 * @param settings
 * @param retryNumber First retry (no increment) = 1, ...
 */
export function getDelay(settings: RetrySettings, retryNumber: number) {
  if ("delayMs" in settings) {
    return settings.delayMs;
  } else {
    const { initialDelayMs, incrementFactor, maxDelayMs } = settings;
    const delayMs = initialDelayMs * Math.pow(incrementFactor, retryNumber);
    return maxDelayMs ? Math.min(delayMs, maxDelayMs) : delayMs;
  }
}

/**
 * RetryWithBackoffError is an error type that needs to be retried with a backoff manner by worker
 */
export class RetryWithDelayError extends Error {
  public readonly retrySettings: RetrySettings;
  public readonly tag: string;

  constructor(error: Error, retrySettings: RetrySettings) {
    super(`Encountered error: ${error.message}. Retrying later`, {
      cause: error,
    });
    this.tag = PrivateErrorTypes.RetryWithDelayError;
    this.retrySettings = retrySettings;
  }

  public static isRetryError<T>(
    err: T,
  ): T extends RetryWithDelayError ? true : false {
    if (!err || typeof err !== "object") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return "message" in err && "stack" in err && "retrySettings" in err;
  }
}
