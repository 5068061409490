import { FC, ReactNode } from "react";

import { Box, BoxProps } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { ThemeUIStyleObject } from "theme-ui";

import {
  AndCondition,
  AndOrCondition,
  Audience,
  AudienceParent,
  ConditionType,
  OrCondition,
  Condition,
  RootCondition,
} from "src/types/visual";
import { Column } from "src/ui/box";

import { QueryBuilderProvider } from "./context/query-builder-context";
import { AndOrConditionField } from "./visual/condition";

export interface QueryBuilderProps {
  audience?: Audience;
  parent: AudienceParent | undefined | null;
  filter: AndOrCondition<AndCondition | OrCondition> | undefined;
  setConditions: (filter: AndCondition | OrCondition | null) => void;
  limit?: number;
  setLimit?: (limit: number) => void;
  sx?: BoxProps["sx"];
}

export const QueryBuilder: FC<Readonly<QueryBuilderProps>> = ({
  audience,
  parent,
  filter = { type: ConditionType.And, conditions: [] as RootCondition[] },
  setConditions,
  sx = {},
}) => {
  /* top level conditions may only have one element. There the type may be defined so that the query builder
    is aware of whether the conditions should be `AND`'d or `OR`'d */
  const relationships = parent?.relationships;
  const traits = parent?.traits;
  const columns = parent?.filterable_audience_columns;
  const events = relationships?.filter(({ to_model: { event } }) => Boolean(event));

  const updateFilter = (newCondition: Condition | AndCondition | OrCondition) => {
    if (newCondition.type !== ConditionType.And && newCondition.type !== ConditionType.Or) {
      Sentry.captureException(
        new Error("The top level condition in the query builder is neither an AND condition nor an OR condition"),
        {
          extra: {
            condition: newCondition,
          },
        },
      );
      return;
    }

    return setConditions({
      ...filter,
      ...newCondition,
    });
  };

  return (
    <QueryBuilderProvider audience={audience} parent={parent}>
      <Box flex={1} mt={4} minWidth={0} overflowX="auto" sx={sx}>
        <VStack gap={8} sx={{ pb: 6, fontSize: 0 }}>
          <AndOrConditionField
            audience={audience}
            columns={columns}
            condition={filter}
            events={events}
            level={0}
            parent={parent}
            relationships={relationships}
            traits={traits}
            onChange={updateFilter}
            // Top level onRemove and onGroup do nothing
            onRemove={() => updateFilter({ ...filter, conditions: [] })}
          />
        </VStack>
      </Box>
    </QueryBuilderProvider>
  );
};

export const VStack: FC<{ children: ReactNode; sx?: ThemeUIStyleObject; gap: number }> = ({ children, sx = {} }) => (
  <Column sx={{ width: "100%", alignItems: "flex-start", ...sx }}>{children}</Column>
);
