import { FC, ReactNode } from "react";

import { PlusIcon } from "@heroicons/react/24/solid";
import { Button, Column, Row } from "@hightouchio/ui";

import { ConditionType } from "src/types/visual";

import { GrayButton } from "./condition";
import { ConnectingLine } from "./condition-sidebar";

interface ConditionWrapperProps {
  children: ReactNode;
  conditionType: ConditionType.And | ConditionType.Or;
  hideGroupButton?: boolean;
  onAddCondition: () => void;
  onAddTrait?: () => void;
}

export const ConditionWrapper: FC<Readonly<ConditionWrapperProps>> = ({
  children,
  conditionType,
  hideGroupButton = false,
  onAddCondition,
  onAddTrait,
}) => {
  return (
    <Row ml={4} width="100%">
      <ConnectingLine sx={{ mt: 8 }} />

      <Column flex={1} minWidth={0}>
        <Row bg="gray.100" borderRadius="6px" flexBasis="fit-content" p={4} position="relative">
          <Column
            sx={{
              "& > :not(:last-child)": { mb: 4 },
            }}
            width="100%"
          >
            {children}
          </Column>
          {onAddTrait && <GrayButton onClick={onAddTrait}>Add trait</GrayButton>}
        </Row>

        {!hideGroupButton && (
          <Row mt={2} sx={{ button: { border: "none" } }}>
            <Button icon={PlusIcon} size="sm" onClick={onAddCondition}>
              {/* Show the opposite here to add a group */}
              {conditionType === ConditionType.And ? "OR" : "AND"}
            </Button>
          </Row>
        )}
      </Column>
    </Row>
  );
};
