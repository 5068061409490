import { forwardRef } from "react";

import pluralize from "pluralize";

import { CreatableSelect, CreatableSelectProps } from "./creatable-select";
import { Option } from "./select";

export type Props = CreatableSelectProps & {
  onChange: (options: Option[]) => void;
};

export const CreatableMultiSelect = forwardRef<any, Props>(
  ({ options = [], value, width, onChange, onCreateOption, placeholder }, ref) => {
    return (
      <CreatableSelect
        ref={ref}
        formatCreateLabel={(label) => `Add ${label}`}
        isClearable={false}
        isMulti={true}
        noOptionsMessage={() => "Type to create a new value..."}
        options={options}
        placeholder={placeholder || "Add multiple values..."}
        tip={`${
          options.length ? `${options.length} selectable ${pluralize("value", options.length)}. ` : ""
        }Type to create a new value.`}
        value={value}
        width={width}
        onChange={onChange}
        onCreateOption={onCreateOption}
      />
    );
  },
);

CreatableMultiSelect.displayName = "CreatableMultiSelect";
