import { CSSProperties, FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Row,
  Column,
  Text,
  TextInput,
  Box,
  Button,
  ButtonGroup,
  ConfirmationDialog,
  IconButton,
  Paragraph,
  Slider,
  Switch,
  TagInput,
  useToast,
  SectionHeading,
  FormField,
  Link,
  Alert,
  Select,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/browser";
import { isEmpty, sortBy, uniq } from "lodash";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Grid, ThemeUIStyleObject } from "theme-ui";
import * as Yup from "yup";

import { ActionBar } from "src/components/action-bar";
import { Permission } from "src/components/permission";
import { usePermission } from "src/contexts/permission-context";
import { ResourcePermissionGrant, useUpdateAudienceSplitsMutation } from "src/graphql";
import { Audience, VisualQueryFilter } from "src/types/visual";
import { Badge } from "src/ui/badge";
import { FieldError } from "src/ui/field";
import { disambiguateSyncs, syncStatusForTag } from "src/utils/syncs";

import {
  AudienceSplit,
  Column as ColumnType,
  NewSplitTestDefinition,
  SplitTestDefinition,
} from "../../../../backend/lib/query/visual/types";
import { Indices } from "../../../../design";
import { constructUpdateSplitsMutationPayload } from "./utils";

type Props = {
  audience: Audience;
  data: VisualQueryFilter["splitTestDefinition"];
  onAddSync: () => void;
  onSave: (data: SplitTestDefinition | undefined, showToast: boolean) => Promise<void>;
};

export const badgeStyles: CSSProperties = {
  position: "relative",
  right: -6,
};

export const tooltipStyles: ThemeUIStyleObject = {
  bg: "white",
  color: "secondary",
  overflow: "visible",
  boxShadow: "large",
};

export const caretStyles: ThemeUIStyleObject = {
  fontSize: 0,
  lineHeight: 2,
  px: 3,
  ":hover": {
    "::before": {
      content: "''",
      position: "absolute",
      top: "-14px",
      left: "16px",
      width: "10px",
      height: "10px",
      bg: "white",
      transform: "rotate(45deg)",
      zIndex: Indices.Modal,
    },
  },
};

const distributionError = "Total distribution must be 100";
const groupNameError = "Group names must be unique";

function getTotalPercentage(splits: AudienceSplit[]): number {
  const splitPercentages = splits?.map((split) => split.percentage);
  return splitPercentages.reduce((sum, percentage) => sum + percentage, 0);
}

const validationSchema = Yup.object().shape({
  groupColumnName: Yup.string().required("Column name required"),
  samplingType: Yup.string()
    .required()
    .test("validate-stratified-sampling-type", "Splits must be assigned to the same sync", function (value) {
      /**
       * Stratified sampling is only allowed if each split is assigned to no more than 1 sync
       * and there is one uniquely assigned sync across all splits
       */
      if (value !== "stratified") {
        return true;
      }

      const allAssignedDestinationInstanceIds = (
        this.parent.splits?.map((split: AudienceSplit) => split.destination_instance_ids) || []
      ).flatMap((syncIds: number) => syncIds);

      const uniqueDestinationInstanceIds = uniq(allAssignedDestinationInstanceIds);

      return uniqueDestinationInstanceIds.length <= 1;
    }),
  splits: Yup.array()
    .of(
      Yup.object().shape({
        friendly_name: Yup.string().required("Split group name required"),
        percentage: Yup.number()
          .typeError("Distribution must be a number")
          .min(1, "Distribution must be between 1 and 99")
          .max(99, "Distribution must be between 1 and 99")
          .required("Distribution required"),
        destination_instance_ids: Yup.array().of(Yup.number()),
      }),
    )
    .test("validate-split-percentage", distributionError, function () {
      return getTotalPercentage(this.parent.splits) === 100;
    })
    .test("validate-group-names", groupNameError, function () {
      return uniq(this.parent.splits?.map((split: AudienceSplit) => split.friendly_name)).length === this.parent.splits?.length;
    }),
  stratificationVariables: Yup.array().of(
    Yup.mixed()
      .transform((variableObj) => (Object.keys(variableObj).length ? variableObj : undefined))
      .required("Stratification variable required"),
  ),
});

const transformToLegacyDataModel = (splitTestDefinition) => ({
  ...splitTestDefinition,
  splits: splitTestDefinition.splits.map((split) => ({
    groupValue: split.friendly_name,
    percentage: split.percentage,
  })),
});

export const Splits: FC<Readonly<Props>> = ({ audience, data: splitTestDefinition, onAddSync, onSave }) => {
  const { toast } = useToast();

  const [splitsEnabled, setSplitsEnabled] = useState(Boolean(splitTestDefinition));
  const [showSaveWarning, setShowSaveWarning] = useState<{
    isOpen?: boolean;
    onConfirm?: () => void;
    confirmButtonText?: string;
  }>({});
  const [isSaving, setIsSaving] = useState(false);

  // Save splits to the old data model and do not show generic "Audience updated" toast
  const saveLegacyDataModelWithoutToast = (data: SplitTestDefinition | undefined) => onSave?.(data, false);

  const updateAudienceSplits = useUpdateAudienceSplitsMutation();

  const audienceSplits: AudienceSplit[] = useMemo(
    () =>
      audience?.splits?.map((split) => ({
        id: split?.id,
        friendly_name: split?.friendly_name,
        column_value: split?.column_value,
        percentage: split?.percentage ? Number(split.percentage) : 0,
        destination_instance_ids:
          split?.destination_instance_splits.map(({ destination_instance_id }) => destination_instance_id) ?? [],
      })) ?? [],
    [audience],
  );

  const syncs = disambiguateSyncs(audience?.syncs);

  const syncOptions = useMemo(() => {
    const options = syncs.map((sync) => ({
      label: sync?.name,
      value: sync.id,
      destinationIcon: sync?.destination?.definition.icon,
      status: syncStatusForTag(sync.status),
    }));

    return sortBy(options, "label");
  }, [syncs]);

  const defaultValues = {
    groupColumnName: splitTestDefinition?.groupColumnName || "",
    samplingType: splitTestDefinition?.samplingType || "non-stratified",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular type problem with Column[]
    stratificationVariables: splitTestDefinition?.stratificationVariables || [],
    splits: audienceSplits.length
      ? audienceSplits
      : [
          { friendly_name: "Group A", percentage: 50, destination_instance_ids: [] },
          { friendly_name: "Group B", percentage: 50, destination_instance_ids: [] },
        ],
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitSuccessful },
    reset,
    setValue,
    watch,
  } = useForm<NewSplitTestDefinition>({
    resolver: yupResolver(validationSchema),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - no circular types until react-hook-form v8
    defaultValues,
  });

  const resetForm = () => {
    reset(defaultValues);
    setSplitsEnabled(Boolean(splitTestDefinition));
  };

  useEffect(() => {
    resetForm();
  }, [splitTestDefinition, audienceSplits]);

  const { fields: stratificationVariableFields, append: stratificationVariableAppend, remove: stratificationVariableRemove } =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - no circular types until react-hook-form v8
    useFieldArray({
      control,
      name: "stratificationVariables",
    });

  const {
    fields: splitGroups,
    append: splitGroupAppend,
    remove: splitGroupRemove,
  } = useFieldArray({
    control,
    name: "splits",
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - no circular types until react-hook-form v8
  const samplingType = watch("samplingType");
  const splits = watch("splits");
  const stratificationVariables = watch("stratificationVariables");

  // After a successful submit reset the form to to a pristine state (so isDirty === false).
  // The only time we don't want to keep the values showing in the frontend if the user disabled splits.
  // That way, if the user decides to re-enable splits (without refreshing the page), they won't see the old values.
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: splitsEnabled });
    }
  }, [isSubmitSuccessful, reset, splitsEnabled]);

  // If the user turns on stratified splits, scroll the page down to hint that new form fields are now displayed.
  // Otherwise it's not obvious that there's new content visible due to the sticky footer.
  useEffect(() => {
    if (samplingType === "stratified") {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }
  }, [samplingType]);

  const updateSplits = async (data: NewSplitTestDefinition | undefined) => {
    // Empty or null data submission means the user has decided to disable splits for this audience.
    if (!splitsEnabled || !data || isEmpty(data)) {
      await updateAudienceSplits.mutateAsync({ removeSplitsIds: audienceSplits.map((split) => String(split.id)) });

      saveLegacyDataModelWithoutToast(undefined);
    } else {
      const newSplitsPayload = data?.splits ?? [];

      const payload = constructUpdateSplitsMutationPayload({
        audienceId: audience?.id,
        newSplits: newSplitsPayload,
        oldSplits: audienceSplits,
      });

      await updateAudienceSplits.mutateAsync({
        addSplits: payload.addSplitsPayload,
        removeSplitsIds: payload.removeSplitsPayload,
        updateSplits: payload.updateSplitsPayload,
        updatedSplitsIds: payload.updatedSplitsIds,
        addDestinationInstanceSplits: payload.addDestinationInstanceSplitsPayload,
      });

      saveLegacyDataModelWithoutToast(transformToLegacyDataModel(data));
    }
  };

  const submit = async (data: NewSplitTestDefinition | undefined) => {
    try {
      setIsSaving(true);

      await updateSplits(data);

      toast({
        id: "change-split-sync-assignments",
        title: "Successfully updated split group assignments.",
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "change-split-sync-assignments",
        title: "There was an error saving your splits configuration.",
        variant: "error",
      });

      Sentry.captureException(error);
    } finally {
      setIsSaving(false);
    }
  };

  // User can click save if either:
  //  - the form has been changed (but not yet saved)
  //  - the form does not have changes, but the user disables splits
  const isSaveEnabled = isDirty || (!splitsEnabled && audienceSplits.length);

  const handleAddSync = useCallback(() => {
    if (isSaveEnabled) {
      setShowSaveWarning({
        isOpen: true,
        confirmButtonText: "Continue",
        onConfirm: () => {
          setShowSaveWarning({});
          onAddSync();
        },
      });
    } else {
      onAddSync();
    }
  }, [isDirty, onAddSync, setShowSaveWarning]);

  const showRemoveSplitButton = Array.isArray(splits) && splits.length > 2;
  const updatePermission = usePermission();

  const header = (
    <Column gap={2} maxW="576px">
      <Row gap={4} align="center">
        <SectionHeading>Enable Splits</SectionHeading>
        <Switch isChecked={splitsEnabled} isDisabled={updatePermission.unauthorized} onChange={setSplitsEnabled} />
      </Row>
      <Paragraph color="text.secondary">
        With splits, you may divide the audience into multiple randomized groups based on specific distributions. These groups
        can be stratified based on multiple variables to ensure that certain subgroups are equally represented in the splits.
      </Paragraph>
    </Column>
  );

  const actionBar = (
    <ActionBar>
      <ButtonGroup>
        <Button isDisabled={!isSaveEnabled} isLoading={isSaving} size="lg" variant="primary" onClick={handleSubmit(submit)}>
          Save changes
        </Button>
        <Button isDisabled={!isSaveEnabled} size="lg" onClick={resetForm}>
          Cancel
        </Button>
      </ButtonGroup>
    </ActionBar>
  );

  if (!splitsEnabled) {
    return (
      <>
        {header}
        {actionBar}
      </>
    );
  }

  return (
    <>
      <Column gap={6} width="100%" mb={32}>
        {header}

        <Controller
          name="groupColumnName"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormField
              description="This column will be created to include the specific split group a user is included in."
              error={error?.message}
              label="Column name"
            >
              <TextInput
                value={field.value}
                onChange={field.onChange}
                width="md"
                isInvalid={Boolean(error)}
                placeholder="Enter a name for your group column (e.g. test_group)..."
              />
            </FormField>
          )}
        />
        <FormField
          label="Split groups"
          description="This defines how your groups should be split in terms of quantity and percentages. For accurate splits,
                Hightouch recommends a minimum audience size of 100 members."
          error={errors?.splits?.message}
        >
          {Boolean(splits?.length) && (
            <Grid
              gap={2}
              sx={{
                mb: 4,
                display: "grid",
                gridTemplateColumns: `minmax(100px, 256px) minmax(min-content, max-content) minmax(300px, max-content) ${
                  showRemoveSplitButton ? "20px" : ""
                }`,
              }}
            >
              {["Groups", "Distribution", "Syncs", showRemoveSplitButton && " "].filter(Boolean).map((columnName, index) => (
                <Text
                  key={`${columnName}-${index}`}
                  color="text.secondary"
                  fontWeight="semibold"
                  size="sm"
                  textTransform="uppercase"
                >
                  {columnName}
                </Text>
              ))}

              {splitGroups.map(({ id }, index) => (
                <Fragment key={id}>
                  <Controller
                    name={`splits.${index}.friendly_name` as const}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <TextInput
                          {...field}
                          isInvalid={Boolean(error || errors?.splits?.message === groupNameError)}
                          placeholder="Enter a group name..."
                        />
                        <FieldError error={error?.message} />
                      </>
                    )}
                  />
                  <Controller
                    control={control}
                    name={`splits.${index}.percentage` as const}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Grid sx={{ gridTemplateColumns: "52px 1fr", columnGap: "8px", alignItems: "flex-start" }}>
                          <Row align="center" gap={2}>
                            <TextInput
                              isInvalid={Boolean(error || errors?.splits?.message === distributionError)}
                              min="1"
                              type="number"
                              value={String(field.value)}
                              onChange={(event) => field.onChange(event.target.value)}
                            />
                            <Text color="text.secondary" fontWeight="semibold">
                              %
                            </Text>
                          </Row>
                          <Row width="140px" mx={2} align="center" height="32px">
                            <Slider
                              aria-label="Split percentage"
                              max={99}
                              min={1}
                              value={field.value}
                              onChange={(value) => field.onChange(value)}
                            />
                          </Row>
                        </Grid>
                        <FieldError error={error?.message} />
                      </>
                    )}
                  />
                  <Controller
                    control={control}
                    name={`splits.${index}.destination_instance_ids` as const}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <TagInput
                          isInvalid={Boolean(error)}
                          optionAccessory={(option) => ({
                            type: "image",
                            url: option.destinationIcon,
                            status: option.status,
                          })}
                          options={syncOptions}
                          placeholder="Select sync..."
                          value={field.value ?? []}
                          width="xl"
                          onChange={(v) => field.onChange(v)}
                        />
                        <FieldError error={error?.message} />
                      </>
                    )}
                  />
                  {showRemoveSplitButton && (
                    <IconButton aria-label="Remove split group" icon={XMarkIcon} onClick={() => splitGroupRemove(index)} />
                  )}
                </Fragment>
              ))}
              <Box sx={{ gridColumnStart: 1 }}>
                <Button
                  onClick={() => {
                    splitGroupAppend({
                      friendly_name: "",
                      percentage: 1,
                      destination_instance_ids: [],
                    });
                  }}
                >
                  Add split group
                </Button>
              </Box>
              <Box sx={{ gridColumnStart: 3 }}>
                <Alert
                  variant={syncOptions.length ? "info" : "warning"}
                  title={
                    syncOptions.length
                      ? "Only syncs attached to this audience can be assigned."
                      : "This audience isn't syncing to any destinations."
                  }
                  message={
                    <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
                      <Text>
                        <Box display="inline-block" onClick={handleAddSync}>
                          <Link href="">Create a new sync</Link>
                        </Box>{" "}
                        for this audience.
                      </Text>
                    </Permission>
                  }
                />
              </Box>
            </Grid>
          )}
        </FormField>

        <Column gap={6} align="flex-start">
          <Column gap={2} maxW="576px">
            <Row gap={4}>
              <SectionHeading>Stratified Sampling (Advanced)</SectionHeading>
              <Badge
                sx={caretStyles}
                tooltip="This feature is currently in beta. Any feedback is greatly appreciated."
                tooltipSx={tooltipStyles}
                variant="blue"
              >
                Beta
              </Badge>
              <Switch
                isChecked={samplingType === "stratified"}
                isDisabled={updatePermission.unauthorized}
                onChange={(enabled) => {
                  setValue("stratificationVariables", []);
                  if (enabled) {
                    stratificationVariableAppend({} as ColumnType);
                  }
                  setValue("samplingType", enabled ? "stratified" : "non-stratified");
                }}
              />
            </Row>
            <Paragraph color="text.secondary">
              Stratified sampling allows users to stratify their groups such that each group will contain an equal allocation of
              values from specified stratification variables.{" "}
              <i>Please note that stratified sampling can only be used with one-time syncs.</i>
            </Paragraph>
            <FieldError error={errors.samplingType?.message} />
          </Column>

          {samplingType === "stratified" && (
            <FormField
              description="The below columns are used to stratify each group. For example, you may want to make sure that your groups are
    stratified along the age factor."
              label="Stratification variables"
            >
              <Column gap={2} align="flex-start">
                {Boolean(stratificationVariables.length) && (
                  <>
                    {stratificationVariableFields.map(({ id }, index) => (
                      <Controller
                        key={id}
                        control={control}
                        name={`stratificationVariables.${index}` as const}
                        render={({ field, fieldState: { error } }) => (
                          <Column gap={2}>
                            <Row align="center" gap={2}>
                              <Select
                                isInvalid={Boolean(error)}
                                options={
                                  audience?.parent?.filterable_audience_columns.map((filterableColumn) => ({
                                    label: filterableColumn.name,
                                    value: filterableColumn.column_reference,
                                  })) ?? []
                                }
                                placeholder="Select a column..."
                                value={field.value}
                                onChange={field.onChange}
                              />
                              {stratificationVariables.length > 1 && (
                                <IconButton
                                  aria-label="Remove stratification variable"
                                  icon={XMarkIcon}
                                  onClick={() => stratificationVariableRemove(index)}
                                />
                              )}
                            </Row>
                            <FieldError error={error?.message} />
                          </Column>
                        )}
                      />
                    ))}
                  </>
                )}
                <Button
                  onClick={() => {
                    stratificationVariableAppend({} as ColumnType);
                  }}
                >
                  Add variable
                </Button>
              </Column>
            </FormField>
          )}
        </Column>
      </Column>

      {actionBar}

      <ConfirmationDialog
        confirmButtonText={showSaveWarning.confirmButtonText || "Continue"}
        isOpen={showSaveWarning.isOpen || false}
        title="Are you sure?"
        variant="danger"
        onClose={() => setShowSaveWarning({})}
        onConfirm={() => {
          if (showSaveWarning.onConfirm) {
            showSaveWarning.onConfirm();
          }
        }}
      >
        <Paragraph>Your unsaved changes will be lost.</Paragraph>
      </ConfirmationDialog>
    </>
  );
};
