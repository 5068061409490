import { FC } from "react";

import { IconProps, Icon } from "src/ui/icons/icon";

export const BidirectionalArrowIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props} viewBox="0 0 20 20">
      <path
        d="M12.7083 14.1667C13.1685 14.1667 13.5416 13.7937 13.5416 13.3334C13.5416 12.8732 13.1685 12.5001 12.7083 12.5001L6.08041 12.5001L7.442 11.2357C7.77926 10.9226 7.79879 10.3953 7.48562 10.058C7.17245 9.72078 6.64517 9.70125 6.30791 10.0144L3.39327 12.7209C3.38677 12.7269 3.38037 12.733 3.37406 12.7392C3.36724 12.7459 3.36053 12.7527 3.35393 12.7597C3.21606 12.9048 3.12995 13.0996 3.12517 13.3145C3.12503 13.3208 3.12496 13.3271 3.12496 13.3334C3.12496 13.5465 3.2049 13.7408 3.33643 13.8882C3.35374 13.9076 3.37202 13.9262 3.39125 13.9441L6.30791 16.6524C6.64517 16.9656 7.17245 16.9461 7.48562 16.6088C7.79879 16.2715 7.77926 15.7443 7.442 15.4311L6.0804 14.1667L12.7083 14.1667Z"
        fill="currentColor"
      />
      <path
        d="M16.875 6.66667C16.875 6.90843 16.772 7.12614 16.6076 7.27836L13.692 9.98566C13.3548 10.2988 12.8275 10.2793 12.5143 9.94205C12.2012 9.60479 12.2207 9.07751 12.558 8.76434L13.9196 7.5L7.29167 7.5C6.83143 7.5 6.45833 7.1269 6.45833 6.66667C6.45833 6.20643 6.83143 5.83333 7.29167 5.83333L13.9195 5.83333L12.558 4.569C12.2207 4.25583 12.2012 3.72855 12.5143 3.39129C12.8275 3.05404 13.3548 3.03451 13.692 3.34768L16.6062 6.05369C16.7715 6.20597 16.875 6.42423 16.875 6.66667C16.875 6.66667 16.875 6.66667 16.875 6.66667Z"
        fill="currentColor"
      />
    </Icon>
  );
};
