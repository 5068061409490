import { FC, ReactNode } from "react";

import { PlusIcon as HeroPlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Box, IconButton, Button as HightouchButton, Text, Column, Row as HightouchRow } from "@hightouchio/ui";
import immutableUpdate from "immutability-helper";
import { ThemeUIStyleObject } from "theme-ui";

import {
  AndCondition,
  AndOrCondition,
  Audience,
  AudienceParent,
  Condition,
  ConditionType,
  EventCondition,
  FilterableColumn,
  initialEventCondition,
  initialNumberOfCondition,
  initialPropertyCondition,
  initialSetCondition,
  NumberOfCondition,
  OrCondition,
  PropertyCondition,
  Relationship,
  SegmentSetCondition,
  TraitDefinition,
} from "src/types/visual";
import { Row } from "src/ui/box";
import { SegmentIcon, PropertyIcon, UsersIcon, EventIcon } from "src/ui/icons";
// eslint-disable-next-line no-restricted-imports
import { Menu } from "src/ui/menu";

import { useQueryBuilderContext } from "../context/query-builder-context";
import {
  addSubcondition,
  updateSubcondition,
  removeSubcondition as removeConditionAtIndex,
  groupSubcondition,
  ungroupSubcondition,
} from "../utils/condition-builders";
import { ConnectingLine, ConditionSidebar } from "./condition-sidebar";
import { ConditionWrapper } from "./condition-wrapper";
import { EventFilter } from "./event-filter";
import { GroupDropdown } from "./group-dropdown";
import { NumberOfFilter } from "./number-of-filter";
import { PropertyFilter } from "./property-filter";
import { ReferencePropertyFilter } from "./reference-property-filter";
import { SegmentSetFilter } from "./segment-set-filter";

enum SetupLinks {
  RelatedModel = "/schema/related-models/new",
  Event = "/schema/events/new",
  Audience = "/audiences/new",
}

const MAX_NESTING_LEVEL = 2;

export interface CommonProps {
  audience: Audience | undefined;
  parent: AudienceParent | undefined | null;
  columns: FilterableColumn[] | undefined;
  events: Relationship[] | undefined;
  relationships: Relationship[] | undefined;
  traits: TraitDefinition[] | undefined;
  onRemove?: () => void;
  onUngroup?: () => void;
  isEventTrait?: boolean;
}

interface ConditionFieldProps extends CommonProps {
  condition: AndOrCondition<Condition>;
  level?: number;
  warningText?: string;
  referenceColumns?: FilterableColumn[];
  onChange: (updatedCondition: Condition | AndCondition | OrCondition) => void;
}

interface AndOrConditionFieldProps extends ConditionFieldProps {
  condition: AndCondition<AndOrCondition<Condition>> | OrCondition<AndOrCondition<Condition>>;
  level?: number;
  warningText?: string;
}

export interface FilterProps<TCondition> extends CommonProps {
  condition: TCondition;
  onChange: (updates: Partial<TCondition>) => void;
}

export const AndOrConditionField: FC<Readonly<AndOrConditionFieldProps>> = ({
  condition,
  level = 0,
  warningText,
  onChange,
  onRemove,
  onUngroup,
  ...props
}) => {
  const isAudienceCreated = props.audience?.id != null;

  const { hasComparableAudiences, hasEvents, hasRelatedModels, onWarnRequirementMissing, selectCondition } =
    useQueryBuilderContext();

  const add = (newCondition: Condition) => {
    onChange(addSubcondition(condition, newCondition));
  };

  const update = (index: number) => (newCondition: Condition) => {
    onChange(updateSubcondition(index, condition, newCondition));
  };

  const remove = (index: number) => () => {
    if (condition.conditions.length === 1) {
      // Remove the parent object (cascading delete)
      onRemove?.();
    } else {
      onChange(removeConditionAtIndex(index, condition));
    }
  };

  const insertGroup = (index: number) => () => {
    onChange(groupSubcondition(index, condition));
  };

  const removeGroup = (index: number) => () => {
    onChange(ungroupSubcondition(index, condition));
  };

  return (
    <>
      <GroupDropdown
        conditionType={condition.type}
        warningText={warningText}
        onChange={(conditionType) => onChange({ ...condition, type: conditionType })}
        onUngroup={onUngroup}
      />
      {condition.conditions.map((nestedCondition, index) => {
        const nestedConditionIsAndOr = [ConditionType.And, ConditionType.Or].includes(nestedCondition.type);

        const sharedProps = {
          condition: nestedCondition,
          level: level + 1,
          onChange: update(index),
          onRemove: remove(index),
        };

        if (nestedConditionIsAndOr) {
          return (
            <ConditionSidebar
              key={index}
              conditionType={condition.type}
              menuSx={{ top: 0 }}
              showDropdown={index !== 0}
              onChange={(conditionType) => onChange({ ...condition, type: conditionType })}
            >
              <ConditionField
                {...props}
                {...sharedProps}
                warningText={condition.type === nestedCondition.type ? "Remove this unused grouping" : undefined}
                onUngroup={removeGroup(index)}
              />
            </ConditionSidebar>
          );
        }

        const canAddTrait = nestedCondition.type === ConditionType.Event && isAudienceCreated;
        const addTrait = canAddTrait ? () => selectCondition(nestedCondition) : undefined;

        return (
          <ConditionSidebar
            key={index}
            conditionType={condition.type}
            showDropdown={index !== 0}
            onChange={(conditionType) => onChange({ ...condition, type: conditionType })}
          >
            <ConditionWrapper
              conditionType={condition.type}
              hideGroupButton={level >= MAX_NESTING_LEVEL}
              onAddCondition={insertGroup(index)}
              onAddTrait={addTrait}
            >
              <ConditionField {...props} {...sharedProps} />
            </ConditionWrapper>
          </ConditionSidebar>
        );
      })}

      <Row
        sx={{
          alignItems: "center",
          width: "100%",
          button: {
            border: "none",
          },
        }}
      >
        <ConditionSidebar
          conditionType={condition.type}
          hasTail={false}
          menuSx={{ top: 2 }}
          variant="light"
          onChange={(conditionType) => onChange({ ...condition, type: conditionType })}
        >
          <ConnectingLine sx={{ ml: "14.5px", mt: 0 }} />

          <Box
            alignItems="center"
            bg="gray.100"
            borderRadius="6px"
            display="flex"
            height="48px"
            p={4}
            position="relative"
            sx={{ button: { bg: "transparent", ":hover": { bg: "gray.200" }, ":active": { bg: "gray.300" } } }}
            width="100%"
          >
            <Menu
              portal
              options={[
                {
                  label: "Have a property",
                  description: "Filter by a column in the table",
                  onClick: () => add(initialPropertyCondition as PropertyCondition),
                  icon: PropertyIcon,
                },
                {
                  label: "Have a relation",
                  description: "Filter by a property in a related model",
                  onClick: () => {
                    if (!hasRelatedModels) {
                      return onWarnRequirementMissing({
                        condition: "related model",
                        to: SetupLinks.RelatedModel,
                      });
                    }

                    add(initialNumberOfCondition as NumberOfCondition);
                  },
                  icon: UsersIcon,
                },
                {
                  label: "Performed an event",
                  description: "Filter by whether they have an associated event",
                  onClick: () => {
                    if (!hasEvents) {
                      return onWarnRequirementMissing({
                        condition: "event",
                        to: SetupLinks.Event,
                      });
                    }

                    add(initialEventCondition as EventCondition);
                  },
                  icon: EventIcon,
                },
                {
                  label: "Part of an audience",
                  description: "Filter by whether they are included or not in an audience",
                  onClick: () => {
                    if (!hasComparableAudiences) {
                      return onWarnRequirementMissing({
                        condition: "audience",
                        to: SetupLinks.Audience,
                      });
                    }

                    add(initialSetCondition as SegmentSetCondition);
                  },
                  icon: SegmentIcon,
                },
              ]}
            >
              <HightouchButton icon={HeroPlusIcon} size="sm" variant="secondary">
                Add condition
              </HightouchButton>
            </Menu>
          </Box>
        </ConditionSidebar>
      </Row>
    </>
  );
};

export const ConditionField: FC<Readonly<ConditionFieldProps>> = ({ condition, level = 0, onChange, ...props }) => {
  const updateCondition = <TCondition,>(updates: Partial<TCondition>) => {
    onChange(immutableUpdate(condition, { $merge: updates }));
  };

  switch (condition.type) {
    case ConditionType.And:
    case ConditionType.Or:
      return (
        <HightouchRow ml={4} width="100%">
          <ConnectingLine />

          <Column width="100%">
            <AndOrConditionField {...props} condition={condition} level={level} onChange={onChange} />
          </Column>
        </HightouchRow>
      );
    case ConditionType.SegmentSet:
      return <SegmentSetFilter {...props} condition={condition} onChange={updateCondition} />;
    case ConditionType.Property:
      return <PropertyFilter {...props} condition={condition} onChange={updateCondition} />;
    case ConditionType.ReferenceProperty:
      return <ReferencePropertyFilter {...props} condition={condition} onChange={updateCondition} />;
    case ConditionType.NumberOf:
      return <NumberOfFilter {...props} condition={condition} onChange={updateCondition} />;
    case ConditionType.Event:
      return <EventFilter {...props} condition={condition} onChange={updateCondition} />;
    default:
      return null;
  }
};

export const HStack: FC<{ children: ReactNode; sx?: ThemeUIStyleObject; gap: number }> = ({ children, sx = {}, gap }) => (
  <Row
    sx={{
      alignItems: "center",
      width: "inherit",
      "& > *:not(:last-child)": { mr: gap, flexShrink: 0 },
      ...sx,
    }}
  >
    {children}
  </Row>
);

export const OperatorLabel: FC<{ children: ReactNode }> = ({ children }) => (
  <Box sx={{ "&>span": { color: "gray.600" } }}>
    <Text>{children}</Text>
  </Box>
);

export const ValueLabel: FC<{ children: ReactNode }> = ({ children }) => <Text>{children}</Text>;

export const RemoveButton: FC<{ onRemove: () => void }> = ({ onRemove }) => (
  <Box sx={{ button: { height: "32px" } }}>
    <IconButton aria-label="Remove condition." icon={XMarkIcon} marginLeft={-2} onClick={onRemove} />
  </Box>
);

export const GrayButton: FC<{ children: ReactNode; onClick: () => void; disabled?: boolean }> = ({
  children,
  onClick,
  disabled,
}) => (
  <Box
    sx={{
      button: {
        height: "32px",
        border: "none",
        backgroundColor: "gray.200",
        fontSize: "12px",
        color: "gray.900",
        ":hover": {
          backgroundColor: "gray.300",
        },
      },
    }}
  >
    <HightouchButton isDisabled={disabled} variant="secondary" onClick={onClick}>
      {children}
    </HightouchButton>
  </Box>
);

export const SmallButton: FC<Readonly<{ children: ReactNode } & typeof HightouchButton["arguments"]>> = ({
  children,
  ...props
}) => (
  <Box
    sx={{
      button: {
        height: "24px",
        px: 2,
        border: "none",
        bg: "gray.200",
        color: "text.primary",
        fontSize: "12px",
        ":hover": {
          bg: "gray.300",
        },
        ":active": {
          bg: "gray.400",
        },
      },
      svg: {
        color: "gray.500",
        marginInlineStart: "0.1rem",
      },
    }}
  >
    <HightouchButton {...props}>{children}</HightouchButton>
  </Box>
);

export const updateSubconditions = (onChange, subconditions, index) => (updates: any) => {
  onChange({ subconditions: immutableUpdate(subconditions, { [index]: { $merge: updates } }) });
};

export const removeSubcondition = (onChange, subconditions, index) => () => {
  onChange({ subconditions: subconditions.filter((_, i) => i !== index) });
};
