import { FC, ReactNode } from "react";

import { SystemStyleObject } from "@chakra-ui/styled-system";
import { Box, Column, Menu, MenuButton, MenuList, MenuItem, Row } from "@hightouchio/ui";

import { ConditionType } from "src/types/visual";

type ConditionSidebarProps = {
  conditionType: ConditionType.And | ConditionType.Or;
  children?: ReactNode;
  hasTail?: boolean;
  menuSx?: SystemStyleObject;
  showDropdown?: boolean;
  variant?: "light" | "normal";
  onChange(type: ConditionType.And | ConditionType.Or): void;
};

const colors = {
  light: {
    [ConditionType.And]: "forest.500",
    [ConditionType.Or]: "ocean.300",
  },
  normal: {
    [ConditionType.And]: "forest.600",
    [ConditionType.Or]: "ocean.600",
  },
};

export const ConditionSidebar: FC<Readonly<ConditionSidebarProps>> = ({
  children,
  conditionType,
  hasTail = true,
  showDropdown = true,
  variant = "normal",
  menuSx = {},
  onChange,
}) => {
  const isAndCondition = conditionType === ConditionType.And;
  const backgroundColor = colors[variant][conditionType];

  return (
    <Column sx={{ position: "relative", minWidth: "100%" }}>
      <Row sx={{ position: "relative", alignItems: "center", mt: 4 }}>
        {showDropdown && (
          <Box
            sx={{
              position: "absolute",
              top: 4,
              display: "flex",
              button: {
                height: "32px",
                width: "35px",
                minWidth: "35px",
                p: 0,
                zIndex: 1,
                border: "none",
                background: `${backgroundColor} !important`,
                fontSize: "12px",
                fontWeight: "medium",
                color: "white",
                ":hover": {
                  background: `${backgroundColor} !important`,
                  color: "white !important",
                },
              },
              ...menuSx,
            }}
          >
            <Menu>
              {/* Current MenuButton always shows the direction icon */}
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <MenuButton directionIcon={undefined}>{conditionType === ConditionType.And ? "AND" : "OR"}</MenuButton>

              <MenuList>
                {/* Background exists as a prop */}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <MenuItem background={isAndCondition ? "avocado.100" : undefined} onClick={() => onChange(ConditionType.And)}>
                  AND
                </MenuItem>
                {/* Background exists as a prop */}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <MenuItem background={!isAndCondition ? "avocado.100" : undefined} onClick={() => onChange(ConditionType.Or)}>
                  OR
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        )}
        {children}
      </Row>
      {hasTail && (
        <Box
          sx={{
            position: "absolute",
            top: "38px",
            height: "100%",
            bg: "gray.300",
            width: "2px",
            ml: "14.5px",
          }}
        />
      )}
    </Column>
  );
};

export const ConnectingLine: FC<Readonly<{ sx?: SystemStyleObject }>> = ({ sx = {} }) => (
  <Box
    sx={{
      height: "2px",
      width: "32px",
      minWidth: "32px",
      mt: 4,
      bg: "gray.300",
      ...sx,
    }}
  />
);
