import { FC } from "react";

import { Box, Button, Menu, MenuButton, MenuList, MenuItem, Row, Tooltip } from "@hightouchio/ui";

import { ConditionType } from "src/types/visual";

import { WarningBadge } from "./warning-badge";

type GroupDropdownProps = {
  conditionType: ConditionType.And | ConditionType.Or;
  warningText?: string;
  onChange: (conditionType: ConditionType.And | ConditionType.Or) => void;
  onUngroup?: () => void;
};

export const GroupDropdown: FC<Readonly<GroupDropdownProps>> = ({ conditionType, warningText, onChange, onUngroup }) => {
  const isAndCondition = conditionType === ConditionType.And;
  const buttonLabel = `${isAndCondition ? "All" : "Any"} of the following are true`;
  const backgroundColor = isAndCondition ? "forest.600" : "ocean.600";

  return (
    <Row position="relative">
      <Box
        sx={{
          display: "flex",
          button: {
            height: "32px",
            px: 2,
            border: "none",
            background: `${backgroundColor} !important`,
            fontSize: "12px",
            fontWeight: "medium",
            color: "white",
            ":hover": {
              background: `${backgroundColor} !important`,
              color: "white !important",
            },
          },
        }}
      >
        <Menu>
          <MenuButton>{buttonLabel}</MenuButton>

          <MenuList>
            {/* Background exists as a prop */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <MenuItem background={isAndCondition ? "avocado.100" : undefined} onClick={() => onChange(ConditionType.And)}>
              All of the following are true (AND)
            </MenuItem>
            {/* Background exists as a prop */}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <MenuItem background={!isAndCondition ? "avocado.100" : undefined} onClick={() => onChange(ConditionType.Or)}>
              Any of the following are true (OR)
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>

      {onUngroup && (
        <Tooltip message="Remove this nesting">
          <Box
            sx={{
              button: {
                height: "32px",
                border: "none",
              },
              ml: 2,
            }}
          >
            <Button size="sm" variant="secondary" onClick={onUngroup}>
              Ungroup
            </Button>
          </Box>
        </Tooltip>
      )}

      {warningText && <WarningBadge sx={{ ml: 2 }} text={warningText} />}

      <Box
        sx={{
          position: "absolute",
          top: "32px", // Height of menu
          height: "48px",
          bg: "gray.300",
          width: "2px",
          ml: "14.5px",
        }}
      />
    </Row>
  );
};
