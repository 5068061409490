import { FC, useMemo, useEffect } from "react";

import { Tooltip } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import { useRelatedAudiencesQuery } from "src/graphql";
import { SegmentSetCondition, segmentSetOperatorOptions } from "src/types/visual";
import { NewSelect } from "src/ui/new-select";

import { AttributeSelect } from "./attribute-select";
import { OperatorLabel, HStack, RemoveButton, FilterProps } from "./condition";
import { validateSegmentSetCondition } from "./condition-validation";
import { Filter } from "./filter";

export const SegmentSetFilter: FC<Readonly<FilterProps<SegmentSetCondition>>> = ({
  audience,
  parent,
  condition,
  onChange,
  onRemove,
}) => {
  const { appAudienceQueryBuilderValidation } = useFlags();
  const { data: relatedAudiencesData, isLoading: loading } = useRelatedAudiencesQuery(
    {
      parentModelId: parent?.id,
      modelId: audience?.id,
    },
    { enabled: Boolean(parent) },
  );
  const filterId = useMemo<string>(uuidv4, []);

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const modelError = filterErrors?.modelId;
  const includesError = filterErrors?.includes;

  useEffect(() => {
    if (appAudienceQueryBuilderValidation) {
      setFieldError(filterId, validateSegmentSetCondition(condition));
    } else {
      removeErrors([filterId]);
    }

    return () => {
      removeErrors([filterId]);
    };
  }, [appAudienceQueryBuilderValidation, condition.modelId, condition.includes, filterId]);

  const relatedAudiencesOptions = relatedAudiencesData?.segments?.map(({ id, name }) => ({ value: id, label: name }));

  const operatorLabel = segmentSetOperatorOptions.find((option) => option.value === condition.includes)?.label;

  return (
    <HStack gap={2} sx={{ alignItems: "flex-start" }}>
      <Filter
        content={
          <NewSelect
            error={Boolean(includesError)}
            options={segmentSetOperatorOptions}
            value={condition.includes}
            onChange={(value) => onChange({ includes: Boolean(value) })}
          />
        }
        error={includesError}
      >
        <OperatorLabel>{`${operatorLabel} in`}</OperatorLabel>
      </Filter>
      <AttributeSelect
        error={modelError}
        loading={loading}
        options={relatedAudiencesOptions}
        placeholder="Select an audience"
        value={condition.modelId}
        onChange={(modelId) => onChange({ modelId })}
      />
      {onRemove && (
        <Tooltip message="Delete this condition">
          <RemoveButton onRemove={onRemove} />
        </Tooltip>
      )}
    </HStack>
  );
};
