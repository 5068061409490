import { useEffect, useMemo, FC } from "react";

import { PlusIcon } from "@heroicons/react/24/solid";
import { Box, Text } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  EventCondition,
  FunnelCondition,
  FunnelValueOptions,
  initialPropertyCondition,
  initialFunnelWindow,
  ConditionType,
  OrCondition,
  AndCondition,
  Condition,
} from "src/types/visual";
import { Row } from "src/ui/box";
import { FilterIcon } from "src/ui/icons/filter";

import { removePropertySubcondition, updateFunnelPropertySubcondition } from "../utils/condition-builders";
import { formatSubconditions } from "../utils/format-subconditions";
import { isAndOrCondition, isPropertyOrReferencePropertyCondition } from "../utils/type-guards";
import { AndOrDropdown } from "./and-or-dropdown";
import { AttributeSelect } from "./attribute-select";
import { ConditionField, FilterProps, HStack, RemoveButton, SmallButton } from "./condition";
import { validateFunnelCondition } from "./condition-validation";
import { WindowFilter } from "./window-filter";

type FunnelFilterProps = FilterProps<FunnelCondition> & { eventCondition: EventCondition };

export const FunnelFilter: FC<Readonly<FunnelFilterProps>> = (props) => {
  const { appAudienceQueryBuilderValidation } = useFlags();
  const { events, eventCondition, condition: unformattedCondition, onChange, onRemove } = props;
  const filterId = useMemo<string>(uuidv4, []);

  // Wrap the subconditions in one and/or condition
  const condition = formatSubconditions(unformattedCondition);
  const topLevelSubcondition = condition.subconditions?.[0];
  // Assumption is that event condition will be formatted to always have one And/Or subcondition as a child (if there are subconditions)
  const subconditions = isAndOrCondition(topLevelSubcondition) ? topLevelSubcondition.conditions : [];

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();
  const filterErrors = getErrors(filterId);

  const eventError = filterErrors?.eventModelId;

  useEffect(() => {
    if (appAudienceQueryBuilderValidation) {
      setFieldError(filterId, validateFunnelCondition(condition));
    } else {
      removeErrors([filterId]);
    }

    return () => {
      removeErrors([filterId]);
    };
  }, [appAudienceQueryBuilderValidation, condition.eventModelId, filterId]);

  const eventName = events?.find(({ id }) => id === eventCondition.relationshipId)?.to_model?.name;

  const eventOptions = events?.map(({ id: relationshipId, name, to_model: { id: eventModelId, name: eventModelName } }) => ({
    label: name ?? eventModelName,
    value: eventModelId,
    relationshipId,
  }));

  const updateWrappingConditionType = () => {
    if (!isAndOrCondition(topLevelSubcondition)) {
      return;
    }

    onChange({
      ...condition,
      subconditions: [
        {
          type: topLevelSubcondition.type === ConditionType.And ? ConditionType.Or : ConditionType.And,
          conditions: topLevelSubcondition?.conditions ?? [],
        },
      ],
    });
  };

  const addSubcondition = () => {
    if (!isAndOrCondition(topLevelSubcondition)) {
      return;
    }

    onChange({
      ...condition,
      subconditions: [
        {
          type: topLevelSubcondition.type,
          conditions: [...topLevelSubcondition.conditions, initialPropertyCondition],
        },
      ],
    });
  };

  const updateSubcondition = (index: number, updates: Condition | OrCondition<Condition> | AndCondition<Condition>) => {
    if (isPropertyOrReferencePropertyCondition(updates)) {
      onChange(updateFunnelPropertySubcondition(index, condition, updates));
    }
  };

  const removeSubcondition = (index: number) => {
    onChange(removePropertySubcondition(index, condition));
  };

  return (
    <>
      <HStack gap={2} sx={{ alignItems: "flex-start" }}>
        <Row sx={{ alignItems: "center", color: "secondaries.10", py: 2, whiteSpace: "nowrap" }}>
          <FilterIcon color="secondaries.10" size={14} />
          <Box alignSelf="center" ml={2}>
            <Text size="sm">{condition.didPerform ? "and then did perform" : "and then did not perform"}</Text>
          </Box>
        </Row>

        <AttributeSelect
          error={eventError}
          options={eventOptions}
          placeholder="Select an event"
          value={condition.eventModelId}
          onChange={(eventModelId, { relationshipId }) => {
            onChange({
              relationshipId,
              eventModelId,
              subconditions: [],
            });
          }}
        />

        {onRemove && <RemoveButton onRemove={onRemove} />}
      </HStack>
      {condition.window && <WindowFilter condition={condition} eventName={eventName} onChange={onChange} />}

      {subconditions?.map((subcondition, index) => {
        if (!isAndOrCondition(topLevelSubcondition)) {
          return null;
        }

        const funnelColumns = events?.find(({ id }) => id === condition.relationshipId)?.to_model?.filterable_audience_columns;
        const referenceColumns = events?.find(({ id }) => id === eventCondition.relationshipId)?.to_model
          ?.filterable_audience_columns;

        return (
          <Row key={index} sx={{ alignItems: "flex-start", pl: 6 }}>
            {index === 0 && (
              <Box alignSelf="center" mr={2}>
                <Text size="sm">WHERE</Text>
              </Box>
            )}
            {index !== 0 && <AndOrDropdown conditionType={topLevelSubcondition.type} onChange={updateWrappingConditionType} />}
            <AttributeSelect
              options={FunnelValueOptions}
              sx={{ mr: 2 }}
              value={subcondition.type}
              width={180}
              onChange={(type) => {
                if (type !== ConditionType.Property && type !== ConditionType.ReferenceProperty) {
                  return;
                }

                updateSubcondition(index, {
                  ...subcondition,
                  type,
                  value: null,
                  valueFromColumn: null,
                });
              }}
            />
            <ConditionField
              {...props}
              columns={funnelColumns}
              condition={subcondition}
              referenceColumns={referenceColumns}
              traits={[]}
              onChange={(updates) => updateSubcondition(index, updates)}
              onRemove={() => removeSubcondition(index)}
            />
          </Row>
        );
      })}
      <HStack gap={4} sx={{ pl: 6 }}>
        <SmallButton disabled={!condition.eventModelId} icon={PlusIcon} onClick={addSubcondition}>
          property
        </SmallButton>
        {!condition.window && (
          <SmallButton
            disabled={!condition.eventModelId}
            icon={PlusIcon}
            onClick={() => {
              onChange({ window: initialFunnelWindow });
            }}
          >
            time window
          </SmallButton>
        )}
      </HStack>
    </>
  );
};
