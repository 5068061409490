import { FC, useMemo, useState } from "react";

import { Box, Column, IconButton, Paragraph, Row, Switch, Text, Tooltip } from "@hightouchio/ui";
import { format as formatDate } from "date-fns";
import { sortBy } from "lodash";
import { format as formatNumber } from "numerable";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";

import { GoalMetricsQuery } from "src/graphql";
import { Audience } from "src/types/visual";
import { CloseSVG, ErrorIcon } from "src/ui/icons/new-icons";

import { graphColors } from "./colors";
import { getMetricKey, useMetricData } from "./use-metric-data";

const valueFormatter = (value: number) => {
  return formatNumber(value, "0,0[.]0a");
};

const sharedStyles = {
  stroke: "#9AA6B2",
  fontFamily: "Inter",
  fontWeight: 600,
  letterSpacing: "0.03em",
};

type PerformanceGraphProps = {
  audience: Audience;
  metric: GoalMetricsQuery["goals"][0] | null;
  onRemove: () => void;
};

export const PerformanceGraph: FC<PerformanceGraphProps> = ({ audience, metric, onRemove }) => {
  const [isNormalized, setIsNormalized] = useState<boolean>(false);

  const {
    metric: { canNormalize, name, data, lines },
  } = useMetricData(metric, isNormalized);

  return (
    <Column border="1px solid" borderColor="base.border" borderRadius="10px">
      <Row align="center" bg="base.background" borderTopRadius="10px" justify="space-between" p={4}>
        <Row gap={1}>
          <Text fontWeight="medium">{name}</Text>
          {isNormalized && (
            <Text color="text.tertiary" fontWeight="medium">
              (per 100 members)
            </Text>
          )}
        </Row>
        <Row align="center">
          {canNormalize && (
            <Row align="center" gap={2} mr={16}>
              <Switch isChecked={isNormalized} onChange={setIsNormalized} />
              <Text fontWeight="medium">Normalize split groups (recommended)</Text>
              <Tooltip message="Divides each split group’s performance by the split group’s size. This allows for accurate comparison between different sized split groups.">
                <Box color="text.secondary">
                  <ErrorIcon size={16} />
                </Box>
              </Tooltip>
            </Row>
          )}

          <Tooltip message="Hide metric">
            <IconButton aria-label="Remove graph." color="text.tertiary" icon={CloseSVG} onClick={onRemove} />
          </Tooltip>
        </Row>
      </Row>

      {data.length === 0 && (
        <Column height="360px" alignItems="center" justifyContent="center">
          <Row maxWidth="460px">
            <Paragraph color="text.secondary" textAlign="center">
              No data is available yet. Metrics are calculated daily so data will be available within 24 hours.
            </Paragraph>
          </Row>
        </Column>
      )}

      {data.length > 0 && (
        <Row>
          <ResponsiveContainer height={360} width="100%">
            <LineChart
              height={350}
              width={800}
              data={data}
              margin={{
                top: 30,
                bottom: 10,
                right: 50,
                left: 0,
              }}
            >
              <CartesianGrid vertical={false} stroke="#E5E9ED" />
              <XAxis
                axisLine={{ stroke: "#E5E9ED" }}
                dataKey="date"
                minTickGap={50}
                domain={[]}
                tickFormatter={(timestamp: string) => formatDate(new Date(timestamp), "LLL d").toUpperCase()}
                tickLine={false}
                {...sharedStyles}
              />
              <YAxis axisLine={false} tickFormatter={valueFormatter} tickLine={false} {...sharedStyles} />

              <RechartsTooltip
                active
                content={
                  <TooltipContent
                    audience={{ id: audience?.id, name: audience?.name ?? "--" }}
                    splits={audience?.splits ?? []}
                  />
                }
                cursor={{ strokeWidth: 2, stroke: "#252D36" }}
              />
              {lines.map(({ id }, index) => (
                <Line
                  key={id}
                  dataKey={id}
                  dot={false}
                  stroke={graphColors[index % graphColors.length]!.color}
                  strokeWidth={2}
                  type="linear"
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </Row>
      )}
    </Column>
  );
};

const TooltipContent = ({
  active,
  audience,
  splits,
  label,
  payload,
}: TooltipProps<number, string> & { audience: { id: string; name: string }; splits: NonNullable<Audience>["splits"] }) => {
  const payloadData = payload?.[0];

  // get data and sort in descending order
  const tooltipData = useMemo(() => {
    if (!payloadData) {
      return splits.map(({ id, friendly_name }, index) => ({
        id,
        name: friendly_name,
        data: null,
        originalIndex: index,
      }));
    }

    let data = splits.map(({ id, friendly_name }, index) => ({
      id,
      name: friendly_name,
      data: payloadData.payload[getMetricKey(audience.id, id)],
      originalIndex: index,
    }));

    if (splits.length === 0) {
      data = [
        {
          id: audience.id,
          name: audience.name,
          data: payloadData.payload[getMetricKey(audience.id, null)],
          originalIndex: 0,
        },
      ];
    }

    data = sortBy(data, "data", "desc");

    return data;
  }, [audience, splits, payloadData]);

  if (!active || !payloadData) {
    return null;
  }

  const dateString = label ?? payloadData?.payload.value;

  return (
    <Column
      bg="white"
      border="1px solid"
      borderColor="#DBE1E8"
      borderRadius="8px"
      maxWidth="400px"
      minWidth="220px"
      mr={3}
      px={3}
      py={2}
      zIndex={2000}
    >
      <Text fontWeight="semibold" mb={2}>
        {formatDate(new Date(dateString), "LLL d, yyyy").toUpperCase().toUpperCase()}
      </Text>

      {tooltipData.map(({ id, name, data, originalIndex }) => (
        <Column key={id} mb={1}>
          <Text color="#697786">{name}</Text>

          <Box bg={graphColors[originalIndex % graphColors.length]!.bg} borderRadius="36px" mt={1} px={2} width="fit-content">
            <Text color={graphColors[originalIndex % graphColors.length]!.color}>{formatNumber(data, "0,0")}</Text>
          </Box>
        </Column>
      ))}
    </Column>
  );
};
