import { createContext, useContext, useState, FC, ReactNode } from "react";

import { ConfirmationDialog, Paragraph, useToast } from "@hightouchio/ui";
import partition from "lodash/partition";
import pluralize from "pluralize";
import { To, useNavigate } from "react-router-dom";
import { noop } from "ts-essentials";

import { AudienceEventTraitForm } from "src/components/audiences/audience-event-trait-form";
import { AudienceTraitForm } from "src/components/audiences/audience-trait-form";
import { useRelatedAudiencesQuery, useUpdateAudienceMutation } from "src/graphql";
import { AdditionalColumn, Audience, EventCondition, TraitCondition, isTraitCondition, AudienceParent } from "src/types/visual";

type Props = {
  audience?: Audience;
  parent?: AudienceParent | null;
  children: ReactNode;
};

type Requirement = { condition: "related model" | "event" | "audience"; to: To };

export type QueryBuilderContextType = {
  hasComparableAudiences: boolean;
  hasEvents: boolean;
  hasRelatedModels: boolean;
  onWarnRequirementMissing: (missingRequirement: Requirement) => void;
  selectedCondition: TraitCondition | EventCondition | null;
  selectCondition: (condition: TraitCondition | EventCondition | null) => void;
};

const defaultContextValue = {
  hasComparableAudiences: false,
  hasEvents: false,
  hasRelatedModels: false,
  onWarnRequirementMissing: noop,
  selectedCondition: null,
  selectCondition: noop,
};

export const QueryBuilderContext = createContext<QueryBuilderContextType>(defaultContextValue);

export const useQueryBuilderContext = () => useContext(QueryBuilderContext);

export const QueryBuilderProvider: FC<Readonly<Props>> = ({ audience, children, parent }) => {
  const { toast } = useToast();
  const navigate = useNavigate();

  const { mutateAsync: updateAudience } = useUpdateAudienceMutation({
    onSuccess: () => {
      // prevents audience query from invalidating
    },
  });

  // Used for adding additional columns
  const [selectedCondition, setSelectedCondition] = useState<TraitCondition | EventCondition | null>(null);
  const [missingRequirement, setMissingRequirement] = useState<Requirement | undefined>();

  const selectCondition = (condition: TraitCondition | EventCondition | null) => setSelectedCondition(condition);

  const handleWarnRequirementMissing = (missingRequirement) => {
    setMissingRequirement(missingRequirement);
  };

  const addAdditionalColumn = async (additionalColumn: AdditionalColumn) => {
    if (!audience) {
      return;
    }

    await updateAudience({
      id: audience.id,
      input: {
        visual_query_filter: {
          ...(audience.visual_query_filter || {}),
          additionalColumns: [additionalColumn, ...(audience.visual_query_filter?.additionalColumns || [])],
        },
      },
    });

    toast({
      id: "add-trait",
      title: "Trait was added",
      variant: "success",
    });
  };

  const parentModel = parent || audience?.parent;

  const relatedAudiencesQuery = useRelatedAudiencesQuery(
    {
      parentModelId: parentModel?.id ?? "",
      modelId: audience?.id,
    },
    {
      enabled: Boolean(parentModel),
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60, // 1 minute
    },
  );

  const relationships = parentModel?.relationships ?? [];
  const [eventModels, relatedModels] = partition(relationships, ({ to_model: { event } }) => Boolean(event));

  const hasRelatedModels = relatedModels.length > 0;
  const hasEvents = eventModels.length > 0;

  const hasComparableAudiences = Boolean(
    relatedAudiencesQuery.data?.segments && relatedAudiencesQuery.data.segments.length > 0,
  );

  return (
    <QueryBuilderContext.Provider
      value={{
        hasComparableAudiences,
        hasEvents,
        hasRelatedModels,
        onWarnRequirementMissing: handleWarnRequirementMissing,
        selectedCondition,
        selectCondition,
      }}
    >
      {children}

      <ConfirmationDialog
        confirmButtonText="Go to set up"
        isOpen={Boolean(missingRequirement)}
        title={`No ${pluralize(missingRequirement?.condition ?? "objects")} found`}
        variant="warning"
        onClose={() => setMissingRequirement(undefined)}
        onConfirm={() => navigate(missingRequirement?.to ?? "")}
      >
        <Paragraph>
          In order to use this condition you need set up {missingRequirement?.condition === "event" ? "an" : "a"}{" "}
          {missingRequirement?.condition}. Would you like to create one now?
        </Paragraph>
      </ConfirmationDialog>

      {selectedCondition &&
        (isTraitCondition(selectedCondition) ? (
          <AudienceTraitForm
            alias=""
            conditions={selectedCondition.property.column.conditions}
            parent={parentModel}
            title="Add trait"
            trait={parentModel?.traits?.find((t) => t.id === selectedCondition.property.column.traitDefinitionId)}
            onClose={() => {
              selectCondition(null);
            }}
            onSubmit={addAdditionalColumn}
          />
        ) : (
          <AudienceEventTraitForm
            condition={selectedCondition}
            parent={parentModel}
            title="Add event trait"
            onClose={() => {
              selectCondition(null);
            }}
            onSubmit={addAdditionalColumn}
          />
        ))}
    </QueryBuilderContext.Provider>
  );
};
