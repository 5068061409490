import { ColumnType, EventTraitColumn, RawColumn, RelatedColumn, TraitColumn } from "@hightouch/lib/query/visual/types";
import {
  AndCondition,
  AndOrCondition,
  Condition,
  ConditionType,
  EventCondition,
  FunnelCondition,
  NumberOfCondition,
  OrCondition,
  PropertyCondition,
  ReferencedPropertyCondition,
  RootCondition,
  SegmentSetCondition,
  TimeType,
  Window,
} from "@hightouch/lib/query/visual/types/condition";
import { AggregationType, AttributionWindow, Goal, GoalConfig } from "@hightouch/lib/query/visual/types/goals";
import { IntervalUnit, RelativeDirection } from "@hightouch/lib/query/visual/types/interval";
import {
  BooleanOperator,
  JsonArrayOperator,
  NumberOperator,
  Operator,
  StringOperator,
  TimestampOperator,
} from "@hightouch/lib/query/visual/types/operator";
import { TraitType, RawSqlTraitConfig } from "@hightouch/lib/query/visual/types/trait-definitions";

import {
  AudienceParentFragment,
  AudienceQuery,
  FilterableColumnFragment,
  RelationshipFragment,
  TraitDefinitionFragment,
} from "src/graphql";
import { Option } from "src/ui/select";

type Audience = AudienceQuery["segments_by_pk"];

export type { VisualQueryFilter, SplitTestDefinition } from "@hightouch/lib/query/visual/types/filter";
export { IntervalUnit } from "@hightouch/lib/query/visual/types/interval";
export type { IntervalValue } from "@hightouch/lib/query/visual/types/interval";
export type { Operator } from "@hightouch/lib/query/visual/types/operator";
export { BooleanOperator, NumberOperator, StringOperator, TimestampOperator, TraitType, ConditionType, ColumnType };
export { AggregationType };
export type {
  Audience,
  AttributionWindow,
  RelationshipFragment as Relationship,
  TraitDefinitionFragment as TraitDefinition,
  FilterableColumnFragment as FilterableColumn,
  AudienceParentFragment as AudienceParent,
  AndCondition,
  AndOrCondition,
  Condition,
  EventCondition,
  FunnelCondition,
  Goal,
  GoalConfig,
  NumberOfCondition,
  OrCondition,
  PropertyCondition,
  ReferencedPropertyCondition,
  RootCondition,
  SegmentSetCondition,
  Window,
  TraitColumn,
  RawColumn,
  RelatedColumn,
  EventTraitColumn,
  RawSqlTraitConfig,
};

export const ColumnDateTypes = [ColumnType.Date, ColumnType.Timestamp];

const operatorToLabel = (operator: string) => {
  return operator
    .replace(/([A-Z])/g, " $1")
    .toLowerCase()
    .trim();
};

export const stringOperatorOptions = Object.keys(StringOperator).map((key) => ({
  label: operatorToLabel(key),
  value: StringOperator[key],
}));

export const numberOperatorOptions = Object.keys(NumberOperator).map((key) => ({
  label: operatorToLabel(key),
  value: NumberOperator[key],
}));

export const numberOfOperatorOptions = [
  {
    label: "exactly",
    value: NumberOperator.Equals,
  },
  {
    label: "not",
    value: NumberOperator.DoesNotEqual,
  },
  { label: "greater than", value: NumberOperator.GreaterThan },
  { label: "less than", value: NumberOperator.LessThan },
  { label: "greater than or equal to", value: NumberOperator.GreaterThanOrEqualTo },
  { label: "less than or equal to", value: NumberOperator.LessThanOrEqualTo },
];

export const eventOperatorOptions = [
  {
    label: "at least",
    value: NumberOperator.GreaterThanOrEqualTo,
  },
  { label: "at most", value: NumberOperator.LessThanOrEqualTo },
  { label: "exactly", value: NumberOperator.Equals },
];

export const segmentSetOperatorOptions = [
  {
    label: "included",
    value: true,
  },
  {
    label: "not included",
    value: false,
  },
];

export const timestampOperatorOptions = [
  { label: "before", value: TimestampOperator.Before },
  { label: "after", value: TimestampOperator.After },
  { label: "within", value: TimestampOperator.Within },
  { label: "not within", value: TimestampOperator.NotWithin },
  { label: "exists", value: TimestampOperator.Exists },
  { label: "does not exist", value: TimestampOperator.DoesNotExist },
  { label: "anniversary", value: TimestampOperator.Anniversary },
  { label: "between", value: TimestampOperator.Between },
];

export const windowOperatorOptions = [
  {
    value: TimestampOperator.Within,
    label: "within",
  },
  {
    value: TimestampOperator.NotWithin,
    label: "not within",
  },
  {
    value: TimestampOperator.Between,
    label: "between",
  },
  {
    value: TimestampOperator.After,
    label: "after",
  },
  {
    value: TimestampOperator.Before,
    label: "before",
  },
];

export const funnelWindowOperatorOptions = [
  {
    value: TimestampOperator.Within,
    label: "within",
  },
  {
    value: TimestampOperator.NotWithin,
    label: "not within",
  },
  {
    value: TimestampOperator.Between,
    label: "between",
  },
];

export const timestampSuggestionsOperatorOptions = [
  { label: "before", value: TimestampOperator.Before },
  { label: "after", value: TimestampOperator.After },
  { label: "exists", value: TimestampOperator.Exists },
  { label: "does not exist", value: TimestampOperator.DoesNotExist },
  { label: "anniversary", value: TimestampOperator.Anniversary },
  { label: "between", value: TimestampOperator.Between },
];

export const booleanOperatorOptions = [
  { label: "equals", value: BooleanOperator.Equals },
  { label: "does not equal", value: BooleanOperator.DoesNotEqual },
  { label: "exists", value: BooleanOperator.Exists },
  { label: "does not exist", value: BooleanOperator.DoesNotExist },
];

export const jsonArrayNumbersOperatorOptions = [
  {
    label: "array contains",
    subLabel: "array contains one of the input values",
    value: JsonArrayOperator.Contains,
  },
  {
    label: "array does not contain",
    subLabel: "array does not contain any of the input values",
    value: JsonArrayOperator.DoesNotContain,
  },
  {
    label: "array is not empty",
    subLabel: "array contains at least one non-null value",
    value: JsonArrayOperator.ContainsNonNull,
    supportedSources: ["postgres", "snowflake"],
  },
];
export const jsonArrayStringsOperatorOptions = [
  {
    label: "array contains (exact)",
    subLabel: "array contains an item which exactly matches one of the input values",
    value: JsonArrayOperator.Contains,
  },
  {
    label: "array does not contain (exact)",
    subLabel: "array does not contain any items which exactly match any of the input values",
    value: JsonArrayOperator.DoesNotContain,
  },
  {
    label: "array contains (partial)",
    subLabel: "array contains an item which partially matches one of the input values",
    value: JsonArrayOperator.ContainsLike,
    supportedSources: ["postgres", "snowflake"],
  },
  {
    label: "array does not contain (partial)",
    subLabel: "array does not contain any items which partially match any of the input values",
    value: JsonArrayOperator.DoesNotContainLike,
    supportedSources: ["postgres", "snowflake"],
  },
  {
    label: "array is not empty",
    subLabel: "array contains at least one non-null value",
    value: JsonArrayOperator.ContainsNonNull,
    supportedSources: ["postgres", "snowflake"],
  },
];

export const DateIntervalOptions = [
  {
    value: IntervalUnit.Day,
    label: "day(s)",
  },
  {
    value: IntervalUnit.Week,
    label: "week(s)",
  },
  {
    value: IntervalUnit.Month,
    label: "month(s)",
  },
  {
    value: IntervalUnit.Year,
    label: "year(s)",
  },
];

export const IntervalOptions = [
  {
    value: IntervalUnit.Minute,
    label: "minute(s)",
  },
  {
    value: IntervalUnit.Hour,
    label: "hour(s)",
  },
  ...DateIntervalOptions,
];

export const OperatorOptions: Record<ColumnType, Array<Option>> = {
  [ColumnType.Boolean]: booleanOperatorOptions,
  [ColumnType.Number]: numberOperatorOptions,
  [ColumnType.String]: stringOperatorOptions,
  [ColumnType.Timestamp]: timestampOperatorOptions,
  [ColumnType.Date]: timestampOperatorOptions,
  [ColumnType.JsonArrayStrings]: jsonArrayStringsOperatorOptions,
  [ColumnType.JsonArrayNumbers]: jsonArrayNumbersOperatorOptions,
  // Not supported
  [ColumnType.Unknown]: stringOperatorOptions,
  [ColumnType.Json]: stringOperatorOptions,
  [ColumnType.Null]: stringOperatorOptions,
};

const referenceStringOperatorOptions = [
  {
    label: "equals",
    value: StringOperator.Equals,
  },
  {
    label: "does not equal",
    value: StringOperator.DoesNotEqual,
  },
];

const referenceTimestampOperatorOptions = [
  { label: "before", value: TimestampOperator.Before },
  { label: "after", value: TimestampOperator.After },
];

export const ReferencePropertyOperatorOptions: Record<ColumnType, Array<Option>> = {
  [ColumnType.Boolean]: [
    { label: "equals", value: BooleanOperator.Equals },
    { label: "does not equal", value: BooleanOperator.DoesNotEqual },
  ],
  [ColumnType.Number]: numberOperatorOptions,
  [ColumnType.String]: referenceStringOperatorOptions,
  [ColumnType.Timestamp]: referenceTimestampOperatorOptions,
  [ColumnType.Date]: referenceTimestampOperatorOptions,
  // Not supported
  [ColumnType.JsonArrayStrings]: jsonArrayStringsOperatorOptions,
  [ColumnType.JsonArrayNumbers]: jsonArrayNumbersOperatorOptions,
  [ColumnType.Unknown]: referenceStringOperatorOptions,
  [ColumnType.Json]: referenceStringOperatorOptions,
  [ColumnType.Null]: referenceStringOperatorOptions,
};

export const ReferencePropertyDefaultOperators: Record<ColumnType, string> = {
  [ColumnType.Boolean]: BooleanOperator.Equals,
  [ColumnType.Number]: NumberOperator.Equals,
  [ColumnType.String]: StringOperator.Equals,
  [ColumnType.Timestamp]: TimestampOperator.Before,
  [ColumnType.Date]: TimestampOperator.Before,
  // Not supported
  [ColumnType.JsonArrayStrings]: JsonArrayOperator.Contains,
  [ColumnType.JsonArrayNumbers]: JsonArrayOperator.Contains,
  [ColumnType.Unknown]: StringOperator.Equals,
  [ColumnType.Json]: StringOperator.Equals,
  [ColumnType.Null]: StringOperator.Equals,
};

export const DefaultOperators: Record<ColumnType, string> = {
  [ColumnType.Boolean]: BooleanOperator.Equals,
  [ColumnType.Number]: NumberOperator.Equals,
  [ColumnType.String]: StringOperator.Equals,
  [ColumnType.Timestamp]: TimestampOperator.Exists,
  [ColumnType.Date]: TimestampOperator.Exists,
  [ColumnType.JsonArrayStrings]: JsonArrayOperator.Contains,
  [ColumnType.JsonArrayNumbers]: JsonArrayOperator.Contains,
  // Not supported
  [ColumnType.Unknown]: StringOperator.Equals,
  [ColumnType.Json]: StringOperator.Equals,
  [ColumnType.Null]: StringOperator.Equals,
};

export const OperatorsWithoutValue = [
  StringOperator.Exists,
  StringOperator.ExistsAndIsNotEmpty,
  NumberOperator.Exists,
  TimestampOperator.Exists,
  TimestampOperator.DoesNotExist,
  TimestampOperator.Anniversary,
  JsonArrayOperator.ContainsNonNull,
];

export const IntervalOperators = [TimestampOperator.Within, TimestampOperator.NotWithin, TimestampOperator.Between];

export const AbsoluteRelativeTimestampOperators = [
  TimestampOperator.Before,
  TimestampOperator.After,
  TimestampOperator.Between,
];

export const RelativeOnlyTimestampOperators = [TimestampOperator.Within, TimestampOperator.NotWithin];

export const initialPropertyCondition: PropertyCondition = {
  type: ConditionType.Property,
  propertyType: null,
  property: null,
  operator: null,
  value: null,
};

export const initialEventCondition: EventCondition = {
  type: ConditionType.Event,
  operator: NumberOperator.GreaterThanOrEqualTo,
  value: 1,
  eventModelId: null,
  relationshipId: null,
  subconditions: [],
};

export const initialEventWindow: Window = {
  operator: TimestampOperator.Within,
  timeType: TimeType.Relative,
  value: { interval: IntervalUnit.Day, quantity: 7, direction: RelativeDirection.Backward },
};

export const initialNumberOfCondition: NumberOfCondition = {
  type: ConditionType.NumberOf,
  relationshipId: null,
  operator: NumberOperator.GreaterThanOrEqualTo,
  value: 1,
  subconditions: [],
};

export const initialSetCondition: SegmentSetCondition = {
  type: ConditionType.SegmentSet,
  modelId: null,
  includes: true,
};

export const initialFunnelCondition: FunnelCondition = {
  type: ConditionType.Funnel,
  eventModelId: null,
  relationshipId: null,
  didPerform: false,
  subconditions: [],
};

export const initialFunnelWindow: Window = {
  operator: TimestampOperator.Within,
  timeType: TimeType.Relative,
  value: { interval: IntervalUnit.Day, quantity: 7, direction: RelativeDirection.Forward },
};

export const MultiValueColumnTypes = [
  ColumnType.String,
  ColumnType.Number,
  ColumnType.JsonArrayStrings,
  ColumnType.JsonArrayNumbers,
];

export const MultiValueOperators = [
  StringOperator.Equals,
  StringOperator.DoesNotEqual,
  StringOperator.Contains,
  StringOperator.DoesNotContain,
  NumberOperator.Equals,
  NumberOperator.DoesNotEqual,
  JsonArrayOperator.ContainsLike,
];

export const FunnelValueOptions = [
  {
    label: "value",
    value: ConditionType.Property,
  },
  {
    label: "property",
    value: ConditionType.ReferenceProperty,
  },
];

export const TraitTypeOptions = [
  {
    label: "Sum",
    value: TraitType.Sum,
  },
  { label: "Count", value: TraitType.Count },
  {
    label: "Average",
    value: TraitType.Average,
  },
  {
    label: "Most frequent",
    value: TraitType.MostFrequent,
  },
  {
    label: "Least frequent",
    value: TraitType.LeastFrequent,
  },
  { label: "First", value: TraitType.First },
  {
    label: "Last",
    value: TraitType.Last,
  },
  {
    label: "SQL",
    value: TraitType.RawSql,
  },
];

export type AdditionalColumn = { alias: string; column: RelatedColumn };

export type ColumnReference = RawColumn | RelatedColumn | TraitColumn | EventTraitColumn;

export interface TraitCondition extends PropertyCondition {
  property: {
    type: "related";
    path: string[];
    column: {
      type: "trait";
      traitDefinitionId: string;
      conditions: PropertyCondition[];
    };
  };
}

export const getInitialTraitColumn = (trait: TraitDefinitionFragment) => ({
  type: "related",
  path: [trait.relationship.id],
  column: {
    type: "trait",
    traitDefinitionId: trait.id,
    conditions: [],
  },
});

export const isColumnReference = (property: unknown): property is ColumnReference => {
  return typeof property === "object";
};

export const isRelatedColumn = (property: string | RawColumn | RelatedColumn | null): property is RelatedColumn => {
  return Boolean(property) && typeof property === "object" && property?.type === "related";
};

export const isTraitColumn = (column: EventTraitColumn | TraitColumn | RawColumn): column is TraitColumn =>
  column.type === "trait";

export const isTraitCondition = (condition: Condition): condition is TraitCondition =>
  condition.type === ConditionType.Property && isRelatedColumn(condition.property) && isTraitColumn(condition.property.column);

/**
 * Returns `true` if `newOperator` cannot suppport the same list of time types as the `oldOperator`
 */
export const shouldResetTimeType = (oldOperator: Operator, newOperator: Operator): boolean => {
  return AbsoluteRelativeTimestampOperators.includes(oldOperator) && RelativeOnlyTimestampOperators.includes(newOperator);
};

/**
 * When using the Between operator, the value is of type `TimeRange` (supports relative and absolute).
 * All other timestamp operators use the value type `IntervalValue` (for relative) or `string` (for absolute).
 * So if the operator transitioned to or from Between, we know its value type has changed.
 */
export const isNewTimeValueTypeDifferent = (oldOperator: Operator, newOperator: Operator): boolean => {
  return [oldOperator, newOperator].includes(TimestampOperator.Between);
};

/**
 * Tests for all conditions where the value type has changed due to the change in operator.
 * If the value _type_ has changed, then we should reset the value.
 */
export const shouldResetValue = (oldOperator, newOperator) => {
  const changedFromIntervalToNonIntervalOperator =
    IntervalOperators.includes(oldOperator) && !IntervalOperators.includes(newOperator);

  const isOperatorWithoutValue = OperatorsWithoutValue.includes(newOperator);

  return (
    changedFromIntervalToNonIntervalOperator || isOperatorWithoutValue || isNewTimeValueTypeDifferent(oldOperator, newOperator)
  );
};
