import { FC, useEffect, useMemo } from "react";

import {
  isIntervalType,
  isTimeRangeType,
  RelativeDirection,
  TimeRangeValue,
  TimeType,
} from "@hightouch/lib/query/visual/types";
import { Box, Tooltip } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEqual, uniq } from "lodash";
import pluralize from "pluralize";
import { Text } from "theme-ui";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import { useColumnSuggestionsQuery } from "src/graphql";
import {
  ColumnReference,
  ColumnType,
  DefaultOperators,
  FilterableColumn,
  getInitialTraitColumn,
  IntervalValue,
  isColumnReference,
  isRelatedColumn,
  isTraitColumn,
  OperatorOptions,
  OperatorsWithoutValue,
  PropertyCondition,
  TraitDefinition,
  TraitType,
  RawSqlTraitConfig,
  shouldResetTimeType,
  shouldResetValue,
} from "src/types/visual";
import { Row } from "src/ui/box";
import { TraitIcon } from "src/ui/icons";
import { NewSelect } from "src/ui/new-select";
import { formatDateOrDatetime } from "src/utils/time";

import { AttributeSelect } from "./attribute-select";
import { FilterProps, HStack, OperatorLabel, ValueLabel, RemoveButton } from "./condition";
import { validatePropertyCondition } from "./condition-validation";
import { Filter } from "./filter";
import { PropertyInput } from "./property-input";
import { TraitFilter } from "./trait-filter";

export type PropertyFilterProps = FilterProps<PropertyCondition> & {
  prefix?: boolean;
};

export const PropertyFilter: FC<Readonly<PropertyFilterProps>> = (props) => {
  const { appAudienceQueryBuilderValidation } = useFlags();
  const { columns, condition, onChange, onRemove, parent, traits } = props;
  const filterId = useMemo<string>(uuidv4, []);

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const propertyError = filterErrors?.property;
  const operatorError = filterErrors?.operator;
  const valueError = filterErrors?.value;

  useEffect(() => {
    if (appAudienceQueryBuilderValidation) {
      setFieldError(filterId, validatePropertyCondition(condition));
    } else {
      removeErrors([filterId]);
    }

    return () => {
      removeErrors([filterId]);
    };
  }, [appAudienceQueryBuilderValidation, condition.property, condition.operator, condition.value, filterId]);

  const isDeprecatedPropertyCondition = !isColumnReference(condition.property);

  const modelId = isColumnReference(condition.property) ? getModelIdFromColumn(condition.property) : parent?.id;
  const columnName = isColumnReference(condition.property) ? getPropertyNameFromColumn(condition.property) : condition.property;

  const { data: columnSuggestionsData, isLoading: loadingSuggestions } = useColumnSuggestionsQuery(
    {
      modelIds: [String(modelId)],
      columnNames: [columnName],
    },
    {
      enabled: modelId !== null && columnName !== null,
    },
  );

  const suggestions = columnSuggestionsData?.getTopK?.columns?.find?.(
    (column) => column.modelId === String(modelId) && column.name === columnName,
  )?.values;

  const mergedModels = uniq([
    ...(columns?.map(({ model_name }) => model_name) || []),
    ...(traits?.map((trait) => trait.relationship.to_model.name) || []),
  ]);
  const isTrait = isRelatedColumn(condition.property) && isTraitColumn(condition.property.column);

  const propertyOptions =
    mergedModels.length > 1
      ? mergedModels.map((model) => {
          const modelColumns = columns?.filter(({ model_name }) => model_name === model);
          const traitsForModel = traits?.filter((trait) => trait.relationship.to_model.name === model);
          const traitOptions = getTraitOptions(condition.property, traitsForModel);
          return {
            label: model === parent?.name ? "Properties" : model,
            options: [...traitOptions, ...getColumnOptions(modelColumns ?? [], isDeprecatedPropertyCondition)],
          };
        })
      : [...getTraitOptions(condition.property, traits), ...getColumnOptions(columns ?? [], isDeprecatedPropertyCondition)];

  const connectionType = props.audience?.connection?.definition.type;
  const operatorOptions = condition.propertyType
    ? OperatorOptions[condition.propertyType].filter(
        (op) => !("supportedSources" in op) || op.supportedSources.includes(connectionType),
      )
    : undefined;
  const operatorLabel = operatorOptions?.find((option) => option.value === condition.operator)?.label;
  const formattedValue = formatValue(condition, condition.value);

  const propertyFilter = (
    <>
      {condition.property && (
        <Filter
          content={
            <Row sx={{ alignItems: "flex-start", gap: "8px" }}>
              <NewSelect
                error={Boolean(operatorError)}
                options={operatorOptions}
                placeholder="Filter on"
                sx={{ flex: "0 0 auto" }}
                value={condition.operator}
                onChange={(value) => {
                  if (shouldResetTimeType(condition.operator, value)) {
                    onChange({ operator: value, timeType: TimeType.Relative, value: null });
                  } else if (shouldResetValue(condition.operator, value)) {
                    onChange({ operator: value, value: null });
                  } else {
                    onChange({ operator: value });
                  }
                }}
              />
              <PropertyInput {...props} error={valueError} suggestions={suggestions} />
            </Row>
          }
          error={operatorError || valueError}
        >
          <OperatorLabel>{operatorLabel}</OperatorLabel>
          <ValueLabel>{formattedValue}</ValueLabel>
        </Filter>
      )}
      {onRemove && (
        <Tooltip message="Delete this condition">
          <RemoveButton onRemove={onRemove} />
        </Tooltip>
      )}
    </>
  );

  return (
    <>
      <HStack gap={2} sx={{ alignItems: "flex-start" }}>
        <AttributeSelect
          disabled={loadingSuggestions}
          error={propertyError}
          loading={loadingSuggestions}
          options={propertyOptions}
          placeholder="Select a property"
          sx={{ flexShrink: 0, borderColor: propertyError ? "red" : undefined }}
          value={condition.property}
          onChange={(value, { type, case_sensitive }) => {
            onChange({
              propertyType: type,
              property: value,
              propertyOptions: {
                caseSensitive: case_sensitive,
              },
              operator: DefaultOperators[type],
              value: null,
            });
          }}
        />
        {!isTrait && propertyFilter}
      </HStack>
      {isTrait && (
        <>
          <TraitFilter {...props} condition={condition} />
          <HStack gap={2}>{propertyFilter}</HStack>
        </>
      )}
    </>
  );
};

export const getColumnOptions = (columns: FilterableColumn[], isDeprecatedPropertyCondition?: boolean) => {
  return columns.map(({ alias, name, type, custom_type, column_reference, case_sensitive }) => ({
    value: isDeprecatedPropertyCondition ? getPropertyNameFromColumn(column_reference) : column_reference,
    label: alias || name,
    type: custom_type || type,
    case_sensitive,
  }));
};

const getModelIdFromColumn = (column: ColumnReference) => {
  if (!column) {
    return null;
  }
  if (column.type === "raw") {
    return column.modelId;
  } else if (column.type === "related") {
    return getModelIdFromColumn(column.column);
  }
};

const getPropertyNameFromColumn = (column: ColumnReference) => {
  if (!column) {
    return null;
  }
  if (column.type === "raw") {
    return column.name;
  } else if (column.type === "related") {
    return getPropertyNameFromColumn(column.column);
  } else if (column.type === "trait") {
    return null;
  }
};

const formatValue = (condition: PropertyCondition, value: any) => {
  if (OperatorsWithoutValue.includes(condition.operator)) {
    return null;
  }
  if (value === null) {
    return <Text>any value</Text>;
  }
  if (Array.isArray(value)) {
    return (
      <Text sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{value.map(String).join(", ")}</Text>
    );
  }
  if (condition.propertyType === ColumnType.Date || condition.propertyType === ColumnType.Timestamp) {
    if (typeof value === "object") {
      return condition.propertyType === ColumnType.Date
        ? getDateValue(value, condition.timeType)
        : getTimeValue(value, condition.timeType);
    }

    return <AbsoluteTimeText hideTime={condition.propertyType === ColumnType.Date} value={value} />;
  }
  return <Text sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{String(value)}</Text>;
};

const getTraitPropertyType = (trait: TraitDefinition) => {
  if (
    trait.type === TraitType.MostFrequent ||
    trait.type === TraitType.LeastFrequent ||
    trait.type === TraitType.First ||
    trait.type === TraitType.Last
  ) {
    const columnReference = trait.config.toSelect;
    const column = trait.relationship.to_model.filterable_audience_columns.find(({ column_reference }) =>
      isEqual(column_reference, columnReference),
    );
    return column?.type;
  } else if (trait.type === TraitType.Count || trait.type === TraitType.Sum || trait.type === TraitType.Average) {
    return ColumnType.Number;
  } else if (trait.type === TraitType.RawSql) {
    return (trait.config as RawSqlTraitConfig).resultingType;
  } else {
    return undefined;
  }
};

const getTraitOptions = (property: PropertyCondition["property"], traits: TraitDefinition[] | undefined) => {
  if (traits) {
    return traits
      .filter((trait) => Boolean(trait.type))
      .map((trait) => ({
        label: trait.name,
        value:
          isRelatedColumn(property) && isTraitColumn(property.column) && property.column.traitDefinitionId === trait.id
            ? property
            : getInitialTraitColumn(trait),
        operator: "exists",
        type: getTraitPropertyType(trait),
        render: () => (
          <>
            <TraitIcon color="base.5" size={16} sx={{ mr: 2 }} />
            <Text sx={{ whiteSpace: "nowrap" }}>{trait.name}</Text>
          </>
        ),
      }));
  }
  return [];
};

const isIntervalNow = (intervalValue: IntervalValue) => {
  return intervalValue.quantity === 0;
};

const isCompletedInterval = (intervalValue: IntervalValue) => {
  return intervalValue.interval && intervalValue.quantity != null;
};

const isCompletedTimeRange = (timeRangeValue: TimeRangeValue) => {
  const { before, after } = timeRangeValue;
  return [before, after].every((v) => typeof v === "string" || (isIntervalType(v) && isCompletedInterval(v)));
};

const AbsoluteTimeText = ({ value, hideTime }: { value: any; hideTime?: boolean }) => {
  return (
    <Text sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
      {formatDateOrDatetime(value, !hideTime)}
    </Text>
  );
};

const RelativeTimeText = ({ value, isFunnelCondition }: { value: any; isFunnelCondition?: boolean }) => {
  if (isIntervalNow(value)) {
    return <Text>now</Text>;
  }

  return (
    <Box display="flex">
      <Text>{pluralize(value.interval, value.quantity, true)}</Text>
      {!isFunnelCondition && <Text ml={1}>{getRelativeDirectionLabel(value)}</Text>}
    </Box>
  );
};

export const getDateValue = (value: any, timeType: TimeType | undefined, funnelCondition?: boolean) => {
  return getTimeValue(value, timeType, funnelCondition, true);
};

const TimeText = ({
  value,
  timeType,
  hideTime,
  isFunnelCondition,
}: {
  value: any;
  timeType: TimeType | undefined;
  hideTime?: boolean;
  isFunnelCondition?: boolean;
}) => {
  if (timeType === TimeType.Absolute) {
    return <AbsoluteTimeText hideTime={hideTime} value={value} />;
  } else {
    return <RelativeTimeText isFunnelCondition={isFunnelCondition} value={value} />;
  }
};

export const getRelativeDirectionLabel = (value: IntervalValue) => {
  if (value.direction === RelativeDirection.Forward) {
    return " from now";
  } else {
    return " ago";
  }
};

export const getTimeValue = (value: any, timeType: TimeType | undefined, isFunnelCondition?: boolean, hideTime?: boolean) => {
  if (isTimeRangeType(value) && isCompletedTimeRange(value)) {
    return (
      <Box display="flex">
        <TimeText hideTime={hideTime} isFunnelCondition={isFunnelCondition} timeType={timeType} value={value.before} />
        <Text sx={{ color: "base.5", mx: 1 }}>and</Text>
        <TimeText hideTime={hideTime} isFunnelCondition={isFunnelCondition} timeType={timeType} value={value.after} />
      </Box>
    );
  }

  if ((isIntervalType(value) && isCompletedInterval(value)) || timeType === TimeType.Absolute) {
    return <TimeText hideTime={hideTime} isFunnelCondition={isFunnelCondition} timeType={timeType} value={value} />;
  }

  return null;
};
