import { ReactNode, FC } from "react";

import { ThemeUICSSObject } from "theme-ui";

import { Column } from "src/ui/box";
import { FieldError } from "src/ui/field";
import { Popout } from "src/ui/popout";

import { HStack } from "./condition";

type Props = {
  children: ReactNode;
  content: ReactNode;
  isPlaceholder?: boolean;
  error?: ReactNode | string | Error;
  sx?: ThemeUICSSObject;
};

export const Filter: FC<Readonly<Props>> = ({ content, isPlaceholder, children, error, sx = {} }) => {
  return (
    <Column sx={sx}>
      <Popout
        content={
          <HStack gap={4} sx={{ overflowX: "auto" }}>
            {content}
          </HStack>
        }
        contentSx={{ p: 2 }}
        minHeight={32}
        sx={{ flex: 1, maxWidth: "fit-content", alignItems: "flex-start" }}
      >
        <HStack
          gap={1}
          sx={{
            border: "small",
            height: "32px",
            alignItems: "center",
            bg: isPlaceholder ? "avocado" : "white",
            borderRadius: "6px",
            borderColor: error ? "red" : isPlaceholder ? "gray.400" : "gray.200",
            boxShadow: "0px 1px 2px 0px #1018280D",
            px: 2,
            whiteSpace: "nowrap",
          }}
        >
          {children}
        </HStack>
      </Popout>

      {error && <FieldError error={error} sx={{ fontWeight: "semi", fontSize: 0 }} />}
    </Column>
  );
};
