import { FC } from "react";

import { Box, Menu, MenuButton, MenuList, MenuItem } from "@hightouchio/ui";

import { ConditionType } from "src/types/visual";

type AndOrDropdownProps = {
  conditionType: ConditionType.And | ConditionType.Or;
  onChange(type: ConditionType.And | ConditionType.Or): void;
};

const colors = {
  [ConditionType.And]: {
    bg: "#EDF7F7",
    color: "forest.600",
  },
  [ConditionType.Or]: {
    bg: "#EBEFFA",
    color: "ocean.600",
  },
};

export const AndOrDropdown: FC<Readonly<AndOrDropdownProps>> = ({ conditionType, onChange }) => {
  const isAndCondition = conditionType === ConditionType.And;
  const variant = colors[conditionType];

  return (
    <Box
      sx={{
        display: "flex",
        button: {
          height: "32px",
          width: "35px",
          minWidth: "35px",
          p: 0,
          mr: 4,
          zIndex: 1,
          border: "1px solid",
          borderColor: variant?.color,
          background: `${variant?.bg} !important`,
          fontSize: "12px",
          fontWeight: "medium",
          color: variant?.color,
          ":hover": {
            background: `${variant?.bg} !important`,
          },
        },
      }}
    >
      <Menu>
        {/* Current MenuButton always shows the direction icon */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <MenuButton directionIcon={undefined}>{isAndCondition ? "AND" : "OR"}</MenuButton>

        <MenuList>
          {/* Background exists as a prop */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <MenuItem background={isAndCondition ? "avocado.100" : undefined} onClick={() => onChange(ConditionType.And)}>
            AND
          </MenuItem>
          {/* Background exists as a prop */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <MenuItem background={!isAndCondition ? "avocado.100" : undefined} onClick={() => onChange(ConditionType.Or)}>
            OR
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};
