import { AudienceSplit } from "@hightouch/lib/query/visual/types";
import differenceBy from "lodash/differenceBy";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import partition from "lodash/partition";

import { AudienceSplitsInsertInput } from "src/graphql";

export const constructUpdateSplitsMutationPayload = ({
  audienceId,
  oldSplits = [],
  newSplits = [],
}: {
  audienceId: string | number;
  oldSplits: AudienceSplit[];
  newSplits: AudienceSplit[];
}) => {
  // Partition splits by objects with and without an 'id' property
  const [existingSplits, addedSplits] = partition(newSplits, "id");

  const addSplitsPayload: AudienceSplitsInsertInput[] = addedSplits.map((split: AudienceSplit) => ({
    segment_id: String(audienceId),
    percentage: split.percentage,
    friendly_name: split.friendly_name,
    column_value: split.friendly_name,
    destination_instance_splits: {
      data: (split.destination_instance_ids ?? []).map((destinationInstanceId) => ({
        destination_instance_id: String(destinationInstanceId),
      })),
    },
  }));

  // get IDs that exist in `oldSplits`, but not in `newSplits`
  const removeSplitsPayload = differenceBy(oldSplits, newSplits, "id").map((split) => String(split.id));

  // do deep equality comparisons between objects to see what splits have changed
  const updatedSplits = differenceWith(existingSplits, oldSplits, isEqual);

  const updateSplitsPayload = updatedSplits.map((split) => ({
    where: { id: { _eq: split.id } },
    _set: omit(split, ["id", "destination_instance_ids"]),
  }));

  // In cases where destination instances change, we need to update the destination_instance_splits table (join table between destination_instances and audience_splits)
  // However, Hasura doesn't support updating nested relations (like you can on insertions)
  // Therefore, we'll just remove the existing rows (by filtering on split ID) and re-insert the rows to handle updates
  const updatedSplitsIds = updatedSplits.map((split) => String(split.id));

  const addDestinationInstanceSplitsPayload = updatedSplits.flatMap((split) =>
    split.destination_instance_ids.map((destinationInstanceId) => ({
      destination_instance_id: String(destinationInstanceId),
      split_id: split.id,
    })),
  );

  return {
    addSplitsPayload,
    removeSplitsPayload,
    updateSplitsPayload,
    updatedSplitsIds,
    addDestinationInstanceSplitsPayload,
  };
};
