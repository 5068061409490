import { FC, useEffect } from "react";

import { Box, Column, Combobox, FormField, Row, Select, TextInput } from "@hightouchio/ui";
import { Controller, UseFormReturn } from "react-hook-form";

import { EventFilter } from "src/components/explore/visual/event-filter";
import { ParentModel } from "src/components/models/parent-model-select";
import {
  AggregationType,
  Audience,
  ConditionType,
  EventCondition,
  initialEventCondition,
  IntervalUnit,
} from "src/types/visual";

import { defaultValues, MetricFormData } from "./use-metric-form";

type MetricFormProps = {
  metricData?: MetricFormData;
  metricFormProps: Omit<UseFormReturn<MetricFormData>, "handleSubmit">;
  parentModel?: ParentModel | null;
};

const AGGREGATION_OPTIONS = [
  { value: AggregationType.Count, label: "Count" },
  { value: AggregationType.Sum, label: "Sum" },
];

const TIME_OPTIONS = [
  { value: IntervalUnit.Day, label: "day(s)" },
  { value: IntervalUnit.Week, label: "week(s)" },
  { value: IntervalUnit.Month, label: "month(s)" },
];

export const MetricForm: FC<MetricFormProps> = ({ metricData = defaultValues, metricFormProps, parentModel }) => {
  const relationships = parentModel?.relationships;
  const traits = parentModel?.traits;
  const columns = parentModel?.filterable_audience_columns;
  const events = relationships?.filter(({ to_model: { event } }) => Boolean(event)) ?? [];

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = metricFormProps;

  useEffect(() => {
    reset(metricData);
  }, [metricData]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular type problem with Condition[]
  const config = watch("config");
  const aggregationType = watch("aggregationType");

  const eventOptions = events?.map(({ id: relationshipId, name, to_model: { id: eventModelId, name: eventModelName } }) => ({
    label: name ?? eventModelName,
    value: eventModelId,
    relationshipId: relationshipId,
  }));

  const filterableColumns =
    events
      ?.find(({ id }) => id === Number(config?.relationshipId))
      ?.to_model?.filterable_audience_columns.map(({ alias, name, column_reference }) => ({
        value: column_reference,
        label: alias || name,
      })) ?? [];

  return (
    <Column gap={4}>
      <Controller
        control={control}
        name="config"
        render={({ field, fieldState: { error } }) => (
          <FormField
            label="Event"
            description="The event to be tracked"
            error={error?.message}
            tip="If you don't see the event you're looking for, make sure it is related to the parent model."
          >
            <Select
              {...field}
              placeholder="Select an event..."
              value={field.value?.eventModelId ? Number(field.value.eventModelId) : undefined}
              options={eventOptions}
              onChange={(eventId: number | undefined) => {
                const event = eventOptions.find(({ value }) => value === eventId);

                if (event) {
                  field.onChange({
                    ...config,
                    type: ConditionType.Event,
                    eventModelId: event.value,
                    relationshipId: event.relationshipId,
                  });
                }
              }}
            />
          </FormField>
        )}
      />

      <Controller
        control={control}
        name="aggregationType"
        render={({ field }) => (
          <FormField label="Aggregation" description="Determines how this metric will be calculated">
            <Select
              {...field}
              options={AGGREGATION_OPTIONS}
              onChange={(value) => {
                if (value === AggregationType.Count) {
                  setValue("column", null);
                }
                field.onChange(value);
              }}
            />
          </FormField>
        )}
      />

      {aggregationType === AggregationType.Sum && (
        <Controller
          control={control}
          name="column"
          render={({ field, fieldState: { error } }) => (
            <FormField
              label="Sum by"
              description="The column that will be summed for all applicable events"
              error={error?.message}
            >
              <Combobox {...field} options={filterableColumns} />
            </FormField>
          )}
        />
      )}

      <Controller
        control={control}
        name="config"
        render={({ field }) => (
          <FormField
            isOptional
            label="Filter conditions"
            description={`Filter  ${
              eventOptions.find(({ value }) => Number(config?.eventModelId) === value)?.label ?? "event"
            } to be counted in this metric`}
          >
            <Column
              bg="gray.50"
              borderRadius="6px"
              flexBasis="fit-content"
              maxWidth="530px"
              p={4}
              position="relative"
              sx={{
                "& > :not(:last-child)": { mb: 4 },
              }}
            >
              <EventFilter
                disableEventSelect
                hideFunnelCondition
                hideOperatorFilter
                hideWindowCondition
                audience={{} as Audience}
                columns={columns}
                events={events}
                condition={
                  field.value
                    ? ({
                        ...field.value.filter,
                        eventModelId: Number(field.value.eventModelId),
                        relationshipId: Number(field.value.relationshipId),
                      } as unknown as EventCondition)
                    : initialEventCondition
                }
                parent={parentModel}
                relationships={relationships}
                traits={traits}
                onChange={(value) => field.onChange({ ...config, filter: value })}
              />
            </Column>
          </FormField>
        )}
      />
      <FormField
        label="Attribution window"
        description="For how long after a person exits an audience should this metric continue to be tracked? Default is 0 days"
        error={errors.attributionWindow?.message}
      >
        <Row>
          <Box maxWidth="70px" mr={4}>
            <Controller
              control={control}
              name="attributionWindow.quantity"
              render={({ field }) => <TextInput {...field} value={field.value.toString()} />}
            />
          </Box>
          <Box maxWidth="150px">
            <Controller
              control={control}
              name="attributionWindow.unit"
              render={({ field }) => <Select options={TIME_OPTIONS} {...field} />}
            />
          </Box>
        </Row>
      </FormField>
    </Column>
  );
};
