import { useState, ReactElement, useMemo, useRef, useEffect, useCallback, Fragment } from "react";

import { isEqual } from "lodash";
import { Text } from "theme-ui";

import { ReloadButton } from "src/ui/button";

import { Input } from "../input";
import { Popout } from "../popout";
import { Option, Value, Placeholder } from "./components";
import { SelectComponentProps, SelectOption, SelectProps } from "./types";

export function NewSelect<Value>({
  disabled = false,
  error,
  width,
  options,
  sx = {},
  onChange,
  value,
  loading = false,
  reload,
  reloadTooltip,
  placeholder,
  strategy = "fixed",
  components,
  searchable,
  autoHide,
}: SelectProps<Value>): ReactElement {
  const selectedRef = useRef<HTMLDivElement | null>(null);
  const searchRef = useRef<HTMLInputElement | null>(null);

  const [search, setSearch] = useState("");

  const filteredOptions = useMemo(() => {
    if (search) {
      const regex = new RegExp(search, "i");
      return options?.reduce((arr, option) => {
        if (option.options) {
          const suboptions = option.options.filter((o) => regex.test(o.label ?? ""));

          if (suboptions.length > 0) {
            return arr.concat({ label: option.label, options: suboptions });
          }
        }

        return regex.test(option.label ?? "") ? arr.concat(option) : arr;
      }, []);
    }

    return options;
  }, [options, search]);

  useEffect(() => {
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({ block: "center" });
    }
  }, [selectedRef]);

  const isSelected = useCallback(
    (option) => {
      return isEqual(value, option.value);
    },
    [value, options],
  );

  const contentSx = {
    py: "2px",
    overflow: "auto",
    minWidth: width ? undefined : "112px",
    width: width ?? "max-content",
    maxWidth: "max(100%, 400px)",
  };

  const ValueComponent = components?.Value ?? Value;
  const PlaceholderComponent = components?.Placeholder ?? Placeholder;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isPlaceholder = typeof value === "undefined" || value === null || value === "";

  const getSelectedOption = (options, group = "") => {
    if (options) {
      for (const option of options) {
        if (option.options) {
          const selected = getSelectedOption(option.options, option.label);
          if (selected) {
            return selected;
          }
        } else {
          const selected = isEqual(value, option.value);
          if (selected) {
            return { option, group };
          }
        }
      }
    }
  };

  return (
    <Popout
      autoHide={autoHide}
      content={({ close }) => {
        const handleChange = (option: SelectOption<Value>) => {
          close();

          if (option.value !== undefined) {
            onChange(option.value, option);
          }
        };
        return (
          <>
            {searchable && (
              <>
                <Input
                  ref={searchRef}
                  placeholder="Search..."
                  sx={{
                    userSelect: "none",
                    mb: 2,
                    px: 3,
                    border: "none",
                    fontSize: 1,
                    ":placeholder": {
                      fontSize: 1,
                    },
                  }}
                  value={search}
                  onChange={(value) => {
                    setSearch(value);
                  }}
                />
              </>
            )}
            {!filteredOptions?.length && <Text sx={{ p: 3, color: "base.6" }}>No options found</Text>}
            {filteredOptions?.map((option, index) => {
              if (option.options) {
                const group = option.label;
                return (
                  <Fragment key={index}>
                    <Text sx={{ fontSize: 0, color: "base.5", fontWeight: "bold", textTransform: "uppercase", p: 3 }}>
                      {group}
                    </Text>
                    {option.options.map((groupedOption) => {
                      const selected = isSelected(groupedOption);
                      const optionDisabled = disabled || option.disabled;
                      return (
                        <Option
                          key={JSON.stringify(option.value)}
                          ref={selected ? selectedRef : undefined}
                          disabled={optionDisabled}
                          option={groupedOption}
                          selected={selected}
                          value={value}
                          onClick={optionDisabled ? undefined : handleChange}
                        />
                      );
                    })}
                  </Fragment>
                );
              } else {
                const selected = isSelected(option);
                const optionDisabled = disabled || option.disabled;
                return (
                  <Option
                    key={JSON.stringify(option.value)}
                    ref={selected ? selectedRef : undefined}
                    disabled={optionDisabled}
                    option={option}
                    selected={selected}
                    value={value}
                    onClick={optionDisabled ? undefined : handleChange}
                  />
                );
              }
            })}
          </>
        );
      }}
      contentSx={contentSx}
      disabled={disabled || loading}
      maxHeight={300}
      strategy={strategy}
      sx={{ flex: 1, ...sx }}
      onOpen={() => {
        setTimeout(() => {
          searchRef.current?.focus();
        }, 100);
      }}
    >
      {({ isOpen }) => {
        const selection = getSelectedOption(options);
        const props: SelectComponentProps = {
          error,
          isOpen,
          disabled,
          loading,
          selectedOption: selection?.option,
          selectedGroup: selection?.group,
        };
        return (
          <>
            {isPlaceholder ? (
              <PlaceholderComponent {...props}>{placeholder}</PlaceholderComponent>
            ) : (
              <ValueComponent {...props} />
            )}
            {reload && (
              <ReloadButton disabled={disabled} loading={loading} sx={{ ml: 2 }} tooltip={reloadTooltip} onClick={reload} />
            )}
          </>
        );
      }}
    </Popout>
  );
}

// For the engineer that comes next trying to remove the forwardRef warnings,
// I tried for an hour or so to infer the Value generic through the forwardRef definition
// yet was unable to and don't want this work to get held up by it
// https://github.com/microsoft/TypeScript/issues/36502
// export const NewSelect = forwardRef<HTMLDivElement, Props<Value>>(NewSelectInner)

// export function NewSelect<Value>(props: Props<Value>) {
//   return forwardRef<HTMLDivElement, Props<Value>>(NewSelectInner)
//   debugger;
//   return (fRef as any).render(props)
// }

// type OurForwardRef<T, Render extends React.ForwardRefRenderFunction<T, P>> = { (render: Render): React.ForwardRefExoticComponent<React.PropsWithoutRef<Parameters<Render>[0]> & React.RefAttributes<T>> }
// const ourForwardRef = forwardRef as OurForwardRef<HTMLDivElement, >
// export const NewSelect = (props) => {
//   const fRef = forwardRef(NewSelectInner);
//   return (fRef as any).render(props)
// }
